.profile-info-diagram {
  display: flex;
  width: 100%;
  background-color: rgba(238, 242, 246, 1);
  align-items: flex-end;
  flex-direction: column;
  height: 54px;
  justify-content: flex-end;
  text-align: left;
  position: relative;
}

.part {
  display: flex;
  align-items: center;
  padding-left: 4px;
  height: 27px;
  font-weight: 450;
  font-size: 12px;
  justify-content: flex-start;
}

.general-expirience {
  //display: flex;
  position: absolute;
  top: 0;
  padding: 3px 10px;
}

.expirience-header {
  display: flex;
  gap: 10px;

  &__item {
    display: flex;
    gap: 5px;

    &__block {
      width: 25px;
      height: 14px;
    }

    &__title {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  }
}
