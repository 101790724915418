.no-overlay {
  &__modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    overflow: auto !important;
  }
  &__modal-content {
    min-height: 50px;
    min-width: 50px;
    max-height: calc(100vh - 100px);
    overflow: auto !important;
  }
}