.replacement-modal {
  width:1160px;
  height: 350px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  &__header {
    margin-top: 60px;
    text-align: center;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }
  &__body {
    margin-top: 24px;
    font-size: 21px;
    line-height: 28px;
    text-align: center;
    &__bold {
      font-weight: 500;
    }
  }
  &__buttons {
    padding-left: 70px;
    padding-right: 70px;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
  }
}