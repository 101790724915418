.delegation-types-item {
  transition-duration: .5s;
  color: #767676;
  min-height: 28px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 12px 13px;
  &:hover {
    background: whitesmoke;
    color: #6DCDF6;
  }
}