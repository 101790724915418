.container {
    min-height: 20vh;
}

.wrapper {
    width: 1160px;
    margin: auto;
    text-align: center;
}

.header {
    margin-top: 20vh;
}

.description {
    color: #EEEEEE;
}
