@import "/src/styles/styles";

.input-composition-default,
.input-composition-default-datepicker {
  width: 587px;
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  &__label {
    &__star {
      padding: 3px;
      color: $gray-secondary
    }
  }
  & label {
    @include input-label-regular();
  }
  & input {
    width: 567px;
    outline: none;
    border: none;
    padding: 12px 0;
    cursor: default;
  }
  &__datepicker_select {
    padding: 12px 0;
    width: 260px;
    min-height: 40px;
  }
  &__description {
    color: $gray-primary;
    margin: 6px 0 3px 0;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
  }

  &__error-message {
    height: 15px;
    line-height: 14px;
    font-size: 12px;
    color: $red-primary;
    &-buffer {
      height: 15px;
    }
  }

  &__no-arrow,
  &__no-arrow-focus,
  &__arrow-down,
  &__arrow-up {
    position: relative;
    cursor: default;
    transition-duration: .5s;
  }
  //&__no-arrow-error {
  //  color: $red-primary;
  //}
  &__arrow-down,
  &__arrow-up,
  &__arrow-up-datepicker,
  &__arrow-down-datepicker {
    &:hover {
      cursor: pointer;
    }
    &::after {
      transition-duration: .5s;
      position: absolute;
      content: "";
      width: 7px;
      height: 7px;
      display: inline-block;
      border-bottom: none;
      border-left: none;
      top: 17px;
      left: 572px;
    }
  }
  &__arrow-up-datepicker,
  &__arrow-down-datepicker {
    &::after {
      left: 250px;
    }
  }
  &__no-arrow {
    border-bottom: 1px solid $stroke-light-2;
    &-focus {
      border-bottom: 1px solid $stroke-light-3;
    }
  }
  &__arrow-down,
  &__arrow-down-datepicker {
    border-bottom: 1px solid $stroke-light-2;
    &::after {
      border: 1px solid $gray-secondary;
      border-bottom: none;
      border-left: none;
      transform: rotate(135deg);
    }
  }
  &__arrow-up,
  &__arrow-up-datepicker {
    border-bottom: 1px solid $stroke-light-3;
    &::after {
      border: 1px solid $gray-primary;
      border-bottom: none;
      border-left: none;
      transform: rotate(-45deg);
    }
  }
  &__popper {
    width: 587px;
    background: $white-primary;
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.09);
    //animation: smoothAppear .12s ease-in;
    overflow: auto;
    & ul {
      list-style-type: none;
    }
    //&-invisible {
    //  animation: smoothDisappear ease-in 1s;
    //}
  }
}
.input-composition-default-datepicker {
  width: 270px;
}



.delegation-transfer {
  &__notification {
    font-size: 14px;
    line-height: 18px;
    display: block;
    position: absolute;
    width: 420px;
    min-height: 200px;
    //max-height: 500px;
    overflow: auto;
    top: 75px;
    left: 750px;
    padding: 35px 25px;
    background: $stroke-light-0;
    -webkit-box-shadow: 0px 5px 15px 1px rgba(158, 169, 187, 0.2);
    -moz-box-shadow: 0px 5px 15px 1px rgba(158, 169, 187, 0.2);
    box-shadow: 0px 5px 15px 1px rgba(158, 169, 187, 0.2);
    &__cross {
      position: absolute;
      top: 15px; left: 380px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      transition-duration: .5s;
    }
    &__cross:before, &__cross:after {
      position: absolute;
      top: 30%;
      left: 50%;
      //top: 15px; left: 335px;
      width: 1px; height: 45%;
      border-radius: 1px;
      transform: rotate(45deg);
      background: currentcolor;
      content: ''
    }
    &__cross:after {
      transform: rotate(-45deg)
    }
    &__cross:hover {
      cursor: pointer;
      background: #e7e3e3;
    }
    &__cross:active {
      background: #dddfe0;
      color: #FFFFFA;
    }

  }
}
