.loginTerminal {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  font-size: 16px;
}

.wrapperEye {
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 0 5px;
}

.loginContainerHelpers {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  row-gap: 649px;
}

.loginHelpersWrapper {
  display: flex;
  column-gap: 20px;
  font-size: 18px;
}

.errorView {
  color: #ed1c24;
}

.coloredTexts {
  font-size: 18px;
  color: #007ac2;
}
