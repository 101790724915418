
.containerInput {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.inputWrapper {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
}

.inputWrapperError {
  border: 1px solid #ed1c24;
}

.inputWrapperDefault {
  border: 1px solid gray;
}

.input {
  border: none;
  width: 80%;
  line-height: 20px;
}

.wrapperEye {
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 0 5px;
}

.errorView {
  color: #ed1c24;
}

.coloredTexts {
  font-size: 18px;
  color: #007ac2;
}
