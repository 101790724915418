
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
    /*max-width: 60%;*/
    /*min-height: 200px;*/
    background: #FFFFFF;
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.06);
    position: relative;
    /*padding: 20px;*/
}

.modal-union {
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;
}

body.show_modal_form {
    overflow: hidden;
}
