
.blueText {
  color: #6DCDF6;
  cursor: pointer;
  font-size: 14px;
}
.confirm{
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 10px;
  align-items: center;
  &__message{
    display: flex;
    flex-direction: column;
    //gap: 18px;
    font-size: 14px;
    line-height: 135%;
    margin-top: 10px;

    &__bold {
      font-weight: 500;

    }
  }
  &__label-invalid {
    color: #ED1C24;
    font-weight: 500;
    width: 400px;
    text-align: center;
    margin-top: 10px;

    //height: 63px;
  }
}
