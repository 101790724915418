.resetPassword__wrapper__label,
.resetPassword__wrapper__label-invalid,
.resetPassword__wrapper__input,
.resetPassword__wrapper__input-invalid,
.resetPassword__button,
.colored-text {
    line-height: 135%;
    font-size: 14px;
}
.resetPassword_wrapper__label,
.resetPassword__wrapper__label-invalid {
    font-size: 14px;
    max-width: 275px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 23px;
}
.resetPassword__wrapper__input,
.resetPassword__wrapper__input-invalid {
    height: 40px;
    background: #fff;
    border: none;
    font-weight: 450;
    padding-right: 20px;
}

.resetPassword {
    display: flex;
    flex-direction: column;
    width: 350px;
    margin: 200px auto auto auto;
    gap: 30px;

    &__logo {
        width: 136px;
        margin-bottom: 30px;
    }

    &__wrapper {
        display: flex;
        width: 275px;
        flex-direction: column;
        position: relative;
        min-width: fit-content;

        &__label-invalid {
            color: rgba(237, 28, 36, 1);
        }

        &__input {
            border-bottom: 1px solid rgba(238, 238, 238, 1);
        }

        &__input::placeholder {
            text-align: left;
        }

        &__input-invalid {
            border-bottom: 1px solid rgba(237, 28, 36, 1);
        }

        &__icon {
            position: absolute;
            cursor: pointer;
            bottom: 10px;
            right: 0;
        }
    }
    &__button {
        width: 275px;
        height: 41px;
        border: 2px solid #6DCDF6;
        border-radius: 42px;
        background: #FFFFFF;
        font-weight: 450;
        letter-spacing: 0.15em;
        transition-duration: 1s;
        text-transform: uppercase;
        margin-bottom: 7px;
        &:hover {
            cursor: pointer;
            background: #f7fafd;
            transition-duration: 1s;
            transform: scale(1.01);
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }
        &:disabled {
            background: rgba(245, 245, 245, 1);
            border: none;
        }
    }
    &__colored-texts{
        margin-top: 60px;
        color: #6DCDF6;
        font-size: 14px;
        cursor: pointer;
        width: 275px;
        &:hover{
            text-underline: #6DCDF6;
            text-decoration: underline;
        }
    }
}
