.division-employee-list {
  cursor: default;
  border-collapse: collapse;
  width: 1160px;
  margin: auto;
  //border: 1px solid lavender;
  &__substitution {
  background: rgba(109, 205, 246, 0.1);
  }
  &__table-header {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #6D6E71;
    text-align: left;
    box-shadow: inset 0px -1px 0px #EEEEEE;
  }
  &__header-{
    &department,
    &employee,
    &position,
    &grade,
    &occupationDate,
    &birthDate,
    &category,
    &agreement {
      padding-top: 32px;
      padding-bottom: 32px;
      //border: .5px solid deeppink;
    }
    &department {
      width: 210px;
    }
    &employee {
      width: 200px;
    }
    &position {
      width: 145px;
    }
    &grade {
      width: 95px;
    }
    &occupationDate {
      width: 105px;
    }
    &birthDate {
      width: 125px;
    }
    &category {
      width: 140px;
    }
    &agreement {
      width: 140px;
    }
  }
  &__table-body {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    box-shadow: inset 0px -1px 0px #EEEEEE;
    .el-department-empty,
    .el-employee-empty,
    .el-position-empty,
    .el-grade-empty,
    .el-occupationDate-empty,
    .el-birthDate-empty,
    .el-category-empty,
    .el-agreement-empty {
      height: 80px;
    }
    .el-department-empty {
      width: 210px;
    }
    .el-employee-empty {
      width: 200px;
    }
    .el-position-empty {
      width: 145px;
    }
    .el-grade-empty {
      width: 95px;
    }
    .el-occupationDate-empty {
      width: 105px;
    }
    .el-birthDate-empty {
      width: 125px;
    }
    .el-category-empty {
      width: 140px;
    }
    .el-agreement-empty {
      width: 140px;
    }

    &__department-empty,
    &__employee-empty,
    &__position-empty,
    &__grade-empty,
    &__occupationDate-empty,
    &__birthDate-empty,
    &__category-empty,
    &__agreement-empty {
      animation: loading;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      border-radius: 4px;
      height: 30px;
      background-color: lavender;
    }
    &__department-empty {
      width: 150px;
    }
    &__employee-empty {
      width: 130px;
    }
    &__position-empty {
      width: 90px;
    }
    &__grade-empty {
      width: 65px;
    }
    &__occupationDate-empty {
      width: 85px;
    }
    &__birthDate-empty {
      width: 90px;
    }
    &__category-empty {
      width: 100px;
    }
    &__agreement-empty {
      width: 90px;
    }

    &__department,
    &__employee,
    &__position,
    &__grade,
    &__occupationDate,
    &__birthDate,
    &__category,
    &__agreement {
      padding-top: 32px;
      padding-bottom: 32px;
    }
    &__department {
      width: 210px;
    }
    &__employee {
      width: 200px;
    }

    &__position {
      width: 145px;
    }

    &__grade {
      width: 95px;
    }

    &__occupationDate {
      width: 105px;
    }

    &__birthDate {
      width: 125px;
    }

    &__category {
      width: 140px;
    }

    &__agreement {
      width: 140px;
      display: flex;
      justify-content: space-between;
      &__kebab {
        &:hover {
          cursor: pointer;
          //background: #ED1C24;
        }
      }
      &__dropdown {
        font-size: 12px;
        line-height: 14px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 20px 25px;
        gap: 12px;
        isolation: isolate;

        position: absolute;
        width: 195px;
        min-height: 80px;
        //left: calc(50% - 195px/2 + 453.5px);
        //top: calc(50% - 80px/2 + 92px);

        background: #FFFFFF;
        box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.09);
        &__item {
          color: #97999B;
          cursor: pointer;
          transition-duration: 1s;
          &:hover {
            transition-duration: 1s;
            color: #6DCDF6;
          }
        }
      }
    }
  }
  &__table-row {
    transition-duration: 1s;
    box-shadow: inset 0px -1px 0px #EEEEEE;
    &:hover {
      transition-duration: 1s;
      transform: scale(1.005);
      background: #f6f6f6;
    }
  }
}
.el-delete-replacement {
  &:hover {
    cursor: pointer;
  }
}

@keyframes loading {
  0% {
    background-color: #FFFFFF;
  }
  50% {
    background-color: #f4f4f4;
  }
  100% {
    background-color: #FFFFFF;
  }
}
