.important-tasks-description {
  width: 587px;
  position: relative;
  height: 100%;

  &__label {
    font-size: 12px;
    line-height: 19px;
  }

  &__textarea,
  &__textarea-error {
    margin-top: 15px;
    //min-height: 32px;
    width: 587px;
    resize: none;
    height: auto;
    outline: none;
    border: none;
    //border-bottom: 1px solid #eeeeee;
    font-size: 16px;
    line-height: 22px;
    font-weight: 450;
    overflow: hidden;
    //word-break: break-all;
    padding-right: 60px;
  }

  &__textarea-error {
    border-bottom: 1px solid #e00000;
  }

  &__char-counter {
    position: absolute;
    top: calc(100% - 40px);
    left: calc(100% - 60px);
    font-size: 12px;
    line-height: 14px;
  }

  &__error {
    color: #e00000;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
  }

  &__error-buffer {
    height: 20px;
  }

  &__message {
    border-bottom: 1px solid #eeeeee;
    color: rgba(151, 153, 155, 1);
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    &-error {
      color: rgba(151, 153, 155, 1);
      height: 20px;
      font-size: 12px;
      line-height: 20px;
      border-bottom:1px solid #e00000;
    }
  }
}
