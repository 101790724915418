.warning-container {
  display: flex;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.15);
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.warning {
    background-color: #ffffff;
    z-index: 10001;
    color: black;
    width: 657px;
    height: 240px;
    padding: 48px 60px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.warning__button {
    display: flex;
    width: 300px;
    max-width: 456px;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    background: rgba(255, 255, 255, 1);
    border: 2px solid rgba(0, 122, 194, 1);
}

.warning__button:hover {
    cursor: pointer;
}