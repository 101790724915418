.ui-cancel-button {
/*  width: 275px;
  height: 41px;
  border: 2px solid #ED1C24;
  border-radius: 42px;
  text-transform: uppercase;
  background: #FFFFFF;

  font-weight: 450;
  font-size: 14px;
  line-height: 21px;

  !* identical to box height, or 150% *!
  letter-spacing: 0.15em;
  transition-duration: 1s;*/

}

.ui-cancel-button:hover {
  cursor: pointer;
/*  transition-duration: 1s;
  background: #f7fafd;

  transform: scale(1.01);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
}