.recruitment-to-work {
  width: 1160px;
  min-height: 600px;
  margin: 62px auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;

  &__employe {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &__select {
      z-index: 52;
    }

    &__details {
      width: 667px;
      min-height: 300px;
      padding: 32px 40px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      background: #F9F9F9;

      & > div:nth-child(1) {
        z-index: auto;
      }

      & > div:nth-child(2) {
        z-index: 2;

      }

      & > div:nth-child(3) {
        z-index: 1;

      }
    }


  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 367px;
    height: 69px;
    padding: 0 20px;
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.0588235294);
    font-size: 14px;
    line-height: 42px;
    font-weight: 400;

    &__blue-plus {
      cursor: pointer;
    }

    &__red-plus {
      cursor: pointer;
    }

  }
}

.some-custom-class {
  position: static;
  z-index: 99;

  & > div {
    & > div {
      position: fixed;
      //background-color: #ED1C24;
      z-index: 99;
    }
  }
}
