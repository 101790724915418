
.login__wrapper__label,
.login__wrapper__label-invalid,
.login__wrapper__input,
.login__wrapper__input-invalid,
.login__button,
.colored-text {
  line-height: 135%;
  font-size: 14px;
}

.login__wrapper__label,
.login__wrapper__label-invalid {
  font-size: 14px;
  max-width: 275px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 23px;
}

.login__wrapper__input,
.login__wrapper__input-invalid {
  height: 40px;
  background: #fff;
  border: none;
  font-weight: 450;
  padding-right: 20px;
}

.login {
  display: flex;
  flex-direction: column;
  width: 350px;
  //padding: 118px 119px 53px 238px;
  margin: 200px auto auto auto;

  gap: 30px;

  &__logo {
    width: 136px;
    margin-bottom: 30px;
  }

  &__wrapper {
    display: flex;
    width: 275px;
    flex-direction: column;
    position: relative;

    &__label-invalid {
      color: rgba(237, 28, 36, 1);
    }

    &__input {
      border-bottom: 1px solid rgba(238, 238, 238, 1);
    }

    &__input-empty {
      cursor: pointer;
    }

    &__input::placeholder {
      text-align: left;
      font-weight: 300;
    }

    &__input-invalid {
      border-bottom: 1px solid rgba(237, 28, 36, 1);
    }

    &__img {
      position: absolute;
      align-self: flex-end;
      top: 35px;
      width: 16px;
      cursor: pointer;
      bottom: 0;
      height: 27px;
      padding-left: 8px;
    }

    &__img-reverse {
      position: absolute;
      align-self: flex-end;
      top: 35px;
      width: 16px;
      cursor: pointer;
      bottom: 0;
      height: 27px;
      padding-left: 8px;
      -moz-transform: scale(-1, -1);
      -o-transform: scale(-1, -1);
      -webkit-transform: scale(-1, -1);
      transform: scale(-1, -1);
    }

    &__icon {
      position: absolute;
      cursor: pointer;
      bottom: 10px;
      right: 0;
    }

    &__checkbox-text {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      margin-top: 30px;
      margin-bottom: 10px;
      gap: 8px;

      div {
        font-size: 14px;
      }
    }

    &__options {
      background-color: white;
      list-style: none;
      border: 1px solid #E3E9F2;
      box-sizing: border-box;
      width: 275px;
      cursor: pointer;
      position: absolute;
      z-index: 11;

      &__disable {
        display: none;
      }

      li {
        justify-content: space-between;
        display: flex;
        height: 35px;
        font-size: 12px;

        div {
          margin-top: 10px;
          margin-left: 10px;
        }

        img {
          margin-top: 10px;
          margin-right: 5px;
          height: 10px;
        }
      }

      li:hover {
        color: rgba(141, 200, 232, 1);
        background: #F4F4F4;
      }

    }

  }

  &__button {
    width: 275px;
    height: 41px;
    border: 2px solid #6DCDF6;
    border-radius: 42px;
    background: #FFFFFF;
    font-weight: 450;
    letter-spacing: 0.15em;
    transition-duration: 1s;
    text-transform: uppercase;
    margin-bottom: 7px;

    &:hover {
      cursor: pointer;
      background: #f7fafd;
      transition-duration: 1s;
      transform: scale(1.01);
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    &:disabled {
      background: rgba(245, 245, 245, 1);
      border: none;
    }
  }

  &__support {
    //margin-top: 60px;
    //margin-left: 400px;
  }
}

.colored-texts {
  color: #6DCDF6;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-underline: #6DCDF6;
    text-decoration: underline;
  }
}
