@import "/src/styles/styles";

.popup-dropdown-search {
  max-height: 500px;
  overflow: auto;
  &__no-items {
    cursor: default;
    display: flex;
    align-items: center;
    height: 48px;
    color: $gray-secondary;
    transition-duration: .5s;
    &__text {
      padding: 8px;
      font-size: 12px;
    }
    &:hover {
      color: $gray-primary;
      background: $stroke-light-0;
    }
  }
  &__input {
    width: 98%;
    height: 28px;
    margin: 5px;
    border: 1px solid rgba(151, 153, 155, 0.25);
  }
  &__list {
    cursor: default;
    color: #767676;
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    &__item {
      display: flex;
      flex-direction: column;
      gap: 10px;
      min-height: 60px;
      padding: 10px;
      & > div:nth-child(1) {
        font-weight: 500;
        line-height: 14px;
        font-size: 12px;
      }
      & > div:nth-child(2) {
        line-height: 13px;
        font-size: 11px;
      }
      & > div:nth-child(3) {
        line-height: 12px;
        font-size: 10px;
      }
      &:hover {
        cursor: pointer;
        color: $blue-light-primary;
        background: $stroke-light-1;
      }
    }
  }
}