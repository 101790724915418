.maintenance-notification {
  position: relative;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
  padding-left: 44px;
  font-size: 16px;
  line-height: 24px;
  width: 1160px;
  border-radius: 5px;
  min-height: 64px;
  background: #fcf5f5;
  box-shadow: 1px 1px 12px #d5d9da;

  &__cross {
    width: 45px;
    height: 35px;
    border-radius: 35%;
    transition-duration: .5s;
  }

  &__cross:before, &__cross:after {
    position: absolute;
    top: 25%;
    left: 1135px;
    width: 1px;
    height: 35px;
    border-radius: 1px;
    transform: rotate(45deg);
    background: currentcolor;
    content: ''
  }

  &__cross:after {
    transform: rotate(-45deg)
  }

  //&__cross:hover {
  //  cursor: pointer;
  //  background: #e7e3e3;
  //}

  //&__cross:active {
  //  background: #dddfe0;
  //  color: #FFFFFA;
  //}

  &:before {
    content: "!";
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 30%;
    left: 10px;
    width: 30px;
    min-height: 30px;
    border-radius: 50%;
    background: red;
    color: whitesmoke;
    font-size: 24px;
  }
}
