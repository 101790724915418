.hp-table-cross {
  border-collapse: collapse;
  width: 1160px;
  margin: 30px auto 0 auto;
  input {
    background-color: #6DCDF6;
  }
  &__row {
    box-shadow: inset 0px -1px 0px #EEEEEE;
  }
  &__head {
    height: 48px;
    &__sub-header-accept-regular > div,
    &__sub-header-accept-none > div,
    &__sub-header-accept-over > div,
    &__sub-header-regular > div,
    &__sub-header-none > div,
    &__sub-header-over > div,
    &__sub-header-cross > div {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #6D6E71;
      font-size: 10px;
      line-height: 14px;
      background: #FAFAFA;
    }
    &__sub-header-accept-none > div,
    &__sub-header-none > div,
    &__sub-header-regular > div {
      color: #FFFFFF;
    }
    &__sub-header-accept-over > div,
    &__sub-header-over > div{
      background: #FFC3C3;
    }
    &__sub-header-accept-regular:nth-child(2) > div {
      justify-content: flex-start;
      color: #0E0E0F;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }
    &__sub-header-regular:first-child > div{
      justify-content: flex-start;
      color: #0E0E0F;
      font-size: 14px;
      line-height: 14px;
      padding: 20px;
    }
    &__sub-header-accept-regular:nth-child(3) > div,
    &__sub-header-regular:nth-child(2) > div {
      padding: 17px;
      justify-content: flex-end;
    }
    &__sub-header-accept-regular:first-child,
    &__sub-header-accept-regular:nth-child(2),
    &__sub-header-accept-regular:nth-child(3),
    &__sub-header-regular:first-child,
    &__sub-header-regular:nth-child(2) {
      border-left: none;
      border-right: none;
    }
    &__sub-header-accept-regular,
    &__sub-header-accept-none,
    &__sub-header-accept-over,
    &__sub-header-regular,
    &__sub-header-none,
    &__sub-header-over,
    &__sub-header-cross {
      border: 1px solid #EEEEEE;
    }
    &__sub-header-accept-checkbox {
      background: #FAFAFA;
      border-top: 1px solid #EEEEEE;
      border-bottom: 1px solid #EEEEEE;
      width: 36px;
      padding: 10px;
    }
    &__sub-header-accept-checkbox > input {
      display: flex;
    }
    &__worker > div,
    &__position > div {
      display: flex;
      vertical-align: center;
      width: 215px;
      font-size: 12px;
      line-height: 16px;
      align-items: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #6D6E71;
    }
    &__position > div {
      width: 190px;
    }
    &__worker > div > div {
      display: flex;
      align-items: center;
      transition-duration: .5s;
      &:hover {
        transition-duration: 1s;
        cursor: pointer;
        color: #0E0E0F;
      }
      &:focus, &:active {
        transition-duration: .3s;
        color: #6DCDF6;
      }
    }

    &__day,
    &__weekend {
      width: 24px;
      font-size: 10px;
      line-height: 14px;
      align-items: center;
      text-align: center;
      letter-spacing: 0.2em;
    }
    &__day {
      color: #0E0E0F;
    }
    &__weekend {
      color: #97999B;
    }
  }
  &__body {
    &__checkbox {
      width: 36px;
      padding: 10px;
    }
    &__worker-accept,
    &__jobTitle-accept {
      height: 60px;
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      color: #000000;
    }
    &__jobTitle-accept {
      overflow: hidden;
    }
    &__worker,
    &__jobTitle {
      height: 60px;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
    }
    &__regular-cell,
    &__planned-cell,
    &__cross-cell,
    &__over-cell,
    &__past-cell {
      width: 24px;
      border: 1px solid #EEEEEE;
    }
    &__planned-cell {
      background: #CDE8F5;
    }
    &__cross-cell {
      background: #FFDDC5;
    }
    &__over-cell {
      background: #FFC3C3;
    }
    &__past-cell {
      background: #F3F3F3;
    }
  }
}