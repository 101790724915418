.delegation-modal-status {
  padding: 65px 12px;
  min-height: 330px;
  width: 1160px;
  margin: 0 auto;
  background: #FAFAFA;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__header {
    margin: 0 auto;
    width: 620px;
    display: flex;
    justify-content: center;
  }
  &__content {
    font-size: 21px;
    text-align: center;
    &__highlight {
      font-weight: 500;
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
