@mixin sub-header-thin () {
  font-size: 22px;
  line-height: 24px;
  font-weight: 300;
}

@mixin sub-header-medium () {
  font-size: 22px;
  line-height: 24px;
  font-weight: 450;
}

