// COLORS
$red-primary: #ED1C24;
$blue-dark-primary: #007AC2;
$blue-light-primary: #6DCDF6;
$gray-primary: #6D6E71;
$white-primary: #FFFFFF;
$black-primary: #000000;

$blue-dark-secondary: #8DC8E8;
$blue-light-secondary: #E2F1F9;
$gray-secondary: #97999B;

$text-black: #000000;
$text-gray: #818C99;


$stroke-light-0: #FAFAFA;
$stroke-light-1: #F4F4F4;
$stroke-light-2: #EEEEEE;
$stroke-light-3: #D7D7D7;


// use mixin to apply overlay or content color
// variables are encapsulated inside mixin
$overlay-dark: #000000;
$overlay-light: #000000;

@mixin overlay-dark($overlay-mixin: true) {
  @if ($overlay-mixin) {
    background: $overlay-dark;
    opacity: 30%;
  } @else {
    color: $overlay-dark;
    opacity: 30%;
  }
}

@mixin overlay-light($overlay-mixin: true) {
  @if ($overlay-mixin) {
    background: $overlay-dark;
    opacity: 15%;
  } @else {
    color: $overlay-dark;
    opacity: 15%;
  }
}

// INDENTATIONS
$indent-slim-thin: 4px;
$indent-medium-thin: 6px;
$indent-regular-thin: 10px;
$indent-bold-thin: 12px;
$indent-super-thin: 24px;
$indent-medium-thick: 36px;
$indent-regular-thick: 48px;
$indent-large-thick: 60px;
$indent-super-thick: 72px;

