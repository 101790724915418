.annual-calendar {
  margin: auto;
  width: 940px;
  padding: 30px 0;
  &__header{
    text-align: center;
    margin: auto;
    padding: 10px 0 20px 0;
    width: 477px;
    font-weight: 400;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #000000;
    &__span{
      margin:  20px;
    }
  }
  &__month-container {
    overflow: auto;
    max-height: 80vh;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 60px 50px;
    &__month {
     &__item{
       background: #ED1C24;
     }
    }
  }
}
.TEST {
  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    display: none;
  }
  .react-datepicker__day--highlighted-custom-1 {
    background: rgba(151, 153, 155, 0.25);
    color: #0E0E0F;
  }
  .react-datepicker__day--highlighted-custom-2{
    background: #E0F4FF;
    color: #0E0E0F;

  }
}

