.report-error {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 34px 98px;
  width:1160px;
  &__statusModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px;
    gap: 40px;

    &__errorText {
      font-weight: 450;
      font-size: 28px;
      line-height: 42px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #000000;
      text-align: center;
    }
  }

  &__main-header {
    font-weight: 700;
    font-size: 26px;
    line-height: 42px;
    color: #6DCDF6;
  }
  &__header {
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000000;

  }
  &__container {
    display: flex;
    justify-content: space-between;
    &__button {
      width: 275px;
      height: 41px;
      border: 2px solid #6DCDF6;
      border-radius: 42px;
      background: #FFFFFF;
      font-weight: 450;
      letter-spacing: 0.15em;
      transition-duration: 1s;
      text-transform: uppercase;
      margin-bottom: 7px;

      &:hover {
        cursor: pointer;
        background: #6DCDF6;
        transition-duration: 1s;
        color: whitesmoke;
        transform: scale(1.01);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }

      &:disabled {
        background: rgba(245, 245, 245, 1);
        border: none;
      }
    }
  }

}
