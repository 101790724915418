.hp-table-week {
  border-collapse: collapse;
  width: 1160px;
  margin: 30px auto;
  & tbody tr {
    box-shadow: inset 0px -1px 0px #EEEEEE;
  }
  &__head {
    height: 80px;
    //border-bottom: .5px solid red;
    box-shadow: inset 0px -1px 0px #EEEEEE;
    &__department,
    &__worker,
    &__vacation-days,
    &__day,
    &__weekend {
      vertical-align: center;
      text-align: left;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #6D6E71;
      word-break: break-word;
    }
    &__department {
      width: 275px;
      padding: 24px 60px 24px 36px;
    }
    &__worker {
      width: 225px;
    }
    &__vacation-days {
      width: 135px;
    }
    &__day,
    &__weekend {
      width: 75px;
      color: #000000;
    }
    &__weekend {
      color: #97999B;
    }
  }
  &__body {
    height: 80px;
    box-shadow: inset 0px -1px 0px #EEEEEE;
    &__department,
    &__worker,
    &__vacation-days,
    &__day {
      vertical-align: center;
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      word-break: break-word;
    }
    &__department {
      width: 275px;
      padding: 24px 60px 24px 36px;
    }
    &__worker {
      width: 225px;
    }
    &__vacation-days {
      width: 135px;
    }
    &__day-regular {
      width: 75px;
      background: #E0F4FF;
    }
    &__day-past {
      background: #EEEEEE;
    }
  }
  &__button-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: 'back cancel . accept';
    width: 1160px;
    margin: 30px auto;
  }

  &__button-container button:last-child {
    grid-area: accept;
  }
  &__button-container button:first-child {
    grid-area: back;
  }
  &__pagination {
    width: 1160px;
    display: flex;
    margin: auto;
    justify-content: flex-end;
    &__center {
      align-self: center;
    }
    &__right {
      margin-left: 450px;
      justify-self: flex-end;
    }
  }
}

.day-regular,
.day-past {
  transition-duration: 1s;
  position: relative;
  width: 100%;
  height: 86px;
  background: #E0F4FF;
  border-bottom: .5px solid #EEEEEE;
  border-top: .5px solid #EEEEEE;
  &__pop-up {
    z-index: 100;
    display: none;
    position: fixed;
    transform: translate(30px, 88px);

    min-height: 50px;
    min-width: 70px;
    background: #FFFFFF;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    &__list {
      list-style: none;
      padding: 20px 30px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      &__dates {
        font-size: 16px;
        color: #6DCDF6;
        text-decoration: underline;
      }
      &__days {
        font-size: 16px;
        color: #878787;
      }
      &__days:before {
        content: '';
        margin: 5px;
      }
    }
  }
}
.day-regular:hover {
  transition-duration: 1s;
  background: #6DCDF6;
  cursor: pointer;
}
.day-past {
  background: #F3F3F3;
}
.day-past:hover {
  transition-duration: 1s;
  background: #e5e4e4;
  cursor: pointer;
}