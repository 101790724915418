.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 630px;
  right: 15px;
  row-gap: 16px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 40px;
  background-color: white;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.06);
}

.button-up {
  transform: rotate(90deg);
}

.button-down {
  transform: rotate(-90deg);
}