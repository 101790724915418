/*.modales.active{*/
/*    opacity: 1 ;*/
/*    pointer-events: all ;*/
/*    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.06) ;*/
/*    !*background-color: rgba(0, 0, 0, 0.8)*!*/
/*    border: solid transparent ;*/
/*    z-index: 99999 ;*/


/*}*/
.modal-support-container{
    flex-direction: column ;
    gap:4vh ;
    padding: 20px;
    height: 250px ;
    width: 50vw ;
    display: flex ;
    /*position: fixed ;*/
    /*align-items: center ;*/
    /*opacity: 0 ;*/
    /*pointer-events: none ;*/
    /*transition: 0.5s ;*/
    /*left: calc(50% - 764px/2) ;*/
    /*top: calc(50% - 280px/2 + 42px) ;*/
    background-color: #FFF ;
    text-align: center ;
    font-size: 16px ;
}
.show-modal-text{
    display: flex ;
    flex-direction: column ;
    justify-content: center ;
    padding: 2% 3% 2% 3% ;
}
.header-support{
    font-family: 'Avenir Next Cyr';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 135%;
    /*margin-top: 4vh;*/
    margin-bottom: 2vh;
}

.big-button-support-container{
    display: flex;
    width: 50vw;
    justify-content: center;
    align-self: center;
    align-items: center;
padding-bottom: 20px;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*padding: 38px 62px;*/
    /*justify-content: flex-end;*/
}
.big-button-support{
    /*display: flex;*/
    margin-left: -1.5vw;
    width: 15vw;
    height: 41px;
    /*background: #f5f5f5;*/
    border-radius: 42px;
    background-color: #fff;
    border: 2px solid rgba(109, 205, 246, 1);
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
    color: #000000;
    letter-spacing: 0.15em;
    line-height: 21px;


}
.big-button-support:hover {
    opacity: 0.5;
    cursor:pointer;

}


