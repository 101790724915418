.regTerminal {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    font-size: 16px;
}

.login__form__wrapper-terminal {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.wrapperEye {
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 0 5px;
}

.regHelpers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 18px;
    row-gap: 500px;
}

.regHelpersWrapper {
    display: flex;
    column-gap: 8px;
    font-size: 18px;
}


.wrapperCheckbox {
    display: flex;
    column-gap: 10px;
    align-items: flex-start;
    font-size: 18px;
}

.checkbox {
    position: relative;
    width: 24px;
    height: 24px;
    appearance: none;
    border: 1px solid #97999B;
}

.checkbox:checked {
    border: 1px solid #007ac2;
    background-color: #007ac2;
}
.checkbox:checked::after {
    position: absolute;
    bottom: 5px;
    left: 6px;
    content: "";
    width: 8px;
    height: 15px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    background-color: #007ac2;
    transform: rotate(40deg);
}

.coloredTexts {
    cursor: pointer;
    font-size: 18px;
    color: #007ac2;
}

.modal {
    padding: 40px;
    width: 1000px;
    height: 1500px;
    border: none;
}
