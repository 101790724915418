.holidays-application-wrapper {

  &__no-content {
    width: 1160px;
    margin: 0 auto;
    height: 80px;
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    animation: showMessage 1s ease;
  }
  &__no-content-false {
    animation: hideMessage 1s ease;
  }
  width: 1160px;
  margin: 0 auto;
}

.holidays-application-no-content {
  &__message {
    align-self: center;
    font-size: 24px;
    line-height: 32px;
    color: #6D6E71;
  }
  &__control {
    position: absolute;
    left: 1050px;
    color: #6DCDF6;
    cursor: pointer;
  }
}

// HEADER

.holidays-application-table,
.holidays-employees-table {
  width: 1160px;
  border-collapse: collapse;
  margin: 0 auto;

  &__row {
    & > td > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__title {
      display: flex;
      min-width: 215px;
    }
    &__percent {
      min-width: 190px;
    }
    height: 48px;
    background: #FAFAFA;
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
  }

  &__checkbox {
    padding: 10px;
  }
  &__title,
  &__percent {
    display: flex;
    align-items: center;
    height: 48px;
    color: #0E0E0F;
  }
  &__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }

  &__percent {
    padding-right: 17px;
    justify-content: flex-end !important;
    color: #6D6E71;

    font-size: 10px;
    line-height: 14px;
  }

  &__day {
    color: #6D6E71;
    font-size: 10px;
    line-height: 14px;

  }
  &__day-cell {
    width: 24px;
    border: 1px solid #EEEEEE;
  }
  &__day-cell-over {
    width: 24px;
    background: #FFC3C3;
    border: 1px solid #EEEEEE;
  }
}

// BODY

.holidays-application-body,
.holidays-employees-table-body {
  &__row {
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
  }

  &__selector {
    display: flex;
  }

  &__checkbox {
    margin: 10px;
  }

  &__name {
    cursor: default;
    display: flex;
    align-items: center;
    height: 60px;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: #000000;
  }
  &__application-name {
    transition-duration: .5s;
    cursor: default;
    display: flex;
    align-items: center;
    height: 60px;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: #6DCDF6;
    &:hover {
      transition-duration: .5s;
      cursor: pointer;
      color: #a4e0fd;
    }
    &:active {
      color: #3cbdfa;

    }
  }

  &__periods-wrapper {
    display: flex;
    align-items: center;
    height: 48px;
    overflow: auto;
  }
  &__periods {
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
  }
  &__day-regular,
  &__day-planned,
  &__day-cross,
  &__day-over,
  &__day-past, {
    border: 1px solid #F3F3F3;
  }
  &__day-planned {
    background: #CDE8F5;
  }
  &__day-cross {
    background: #FFDDC5;
  }
  &__day-over {
    background: #FFC3C3;
  }
  &__day-past {
    background: #EEEEEE;
  }
}

@keyframes showMessage {
  from {
    height: 0;
  }
  to {
    height: 80px;
  }
}
@keyframes hideMessage {
  from {
    height: 80px;
  }
  to {
    height: 0;
  }
}