.hp-header {
  width: 1160px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 35px;
  &__upper,
  &__lower {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &__left,
    &__right {
      font-weight: 400;
      font-size: 28px;
      line-height: 30px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }
    &__left {
      color: #000000;
    }
    &__right {
      color: #FD8E28;
    }
  }
}

.hp-tabs,
.hp-visual {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #6D6E71;

  display: flex;
  gap: 30px;
  padding: 0;
  list-style: none;

  &__item {
    cursor: pointer;
    color: #6D6E71;
    &__active {
      cursor: pointer;
      color: #6DCDF6;
    }
  }
}