@import url('../../../../styles/default/_variables.scss');
.phone-verify,
.phone-verify__message {
  display: flex;
  flex-direction: column;
}
.phone-verify {
 margin: 200px auto auto auto;
  //height: 607px;
}

.verification-modal__phone-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  //height: 500px;
  margin: auto;
}
.phone-verify,
.verification-modal__phone-form {
  width: 315px;
  //margin: auto;
  //margin: 200px auto auto auto;

  //height: 607px;
  gap: 34px;
  &__logo {
    width: 139px;
    margin-bottom: 30px;
  }
  &__message{
    gap: 8px;
    font-size: 14px;
    line-height: 135%;
    margin-bottom: 17px;
    &__bold {
      font-weight: 500;

    }
  }
  &__label-invalid {
    color: #ED1C24;
    font-weight: 500;
    height: 63px;
  }
  &__refresh{
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
    line-height: 135%;
    margin-bottom: 14px;
  }
  &__button{
    width: 275px;
    height: 41px;
    border: 2px solid #6DCDF6;
    border-radius: 42px;
    background: #FFFFFF;
    margin-bottom: 60px;
    //font
    font-weight: 450;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    // transition
    transition-duration: 1s;
    &:hover {
      cursor: pointer;
      background: rgba(109, 205, 246, 1);
      color: whitesmoke;
      transition-duration: 1s;
      transform: scale(1.01);
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
  }
  &__support{
   color: #6DCDF6;
    cursor: pointer;
    font-size: 14px;
  }

}
//INPUT
.character {
  &__container {
    height: 48px;
    width: 275px;
  }
  line-height: 150%;
  font-size: 30px;
  color: black;
  box-shadow: #6DCDF6;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #6DCDF6;
  margin-left: 8px;
  &:first-child {
    margin-left: 0;
  }
  &--inactive {
    background-color: rgba(255, 255, 255, 0.1);
    /*color: #FFFFFF;*/
  }
  &--selected {
    transition: box-shadow .20s;
    border: 1px solid rgba(109, 205, 246, 1);
    box-shadow: 0px 6px 24px rgba(109, 205, 246, 1);

  }
  &-invalid {
    width: 275px;
    line-height: 150%;
    font-size: 30px;
    color: black;
    background-color:rgba(255, 255, 255, 0.2);
    border: 1.5px solid rgba(237, 28, 36, 1);
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
  }
}
.colored-text {
  color:  rgba(109, 110, 113, 0.25);
}
.verification-modal__phone-form__button {
  margin : 0;
}
