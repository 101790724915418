.hp-table-quart {
  border-collapse: collapse;
  width: 1160px;
  margin: 30px auto;
  & tbody tr {
    box-shadow: inset 0px -1px 0px #EEEEEE;
  }
  &__head {
    height: 80px;
    box-shadow: inset 0px -1px 0px #EEEEEE;
    &__department,
    &__worker,
    &__vacation-days,
    &__table-header-quart {
      vertical-align: center;
      text-align: left;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #6D6E71;
      word-break: break-word;
    }
    &__table-header-quart {
      width: 204px;
    }
    &__department {
      width: 236px;
    }
    &__worker {
      width: 200px;
    }
    &__vacation-days {
      width: 112px;
    }
  }
  &__body {
    &__department,
    &__worker,
    &__vacation-days,
    &__week {
      height: 80px;
      text-align: left;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      padding: 5px;
    }
    &__week {
      padding: 0;
    }
  }
}
.week-regular,
.week-past {
  transition-duration: 1s;
  position: relative;
  width: 100%;
  height: 100%;
  background: #E0F4FF;
  border-bottom: .5px solid #EEEEEE;
  border-top: .5px solid #EEEEEE;
  &__pop-up {
    z-index: 100;
    display: none;
    position: fixed;
    //top: 75%;
    //left: 50%;
    //top: 0;
    //left: 0;

    transform: translate(30px, 83px);

    min-height: 50px;
    min-width: 70px;
    background: #FFFFFF;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    //overflow: visible;
    &__list {
      list-style: none;
      padding: 20px 30px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      &__dates {
        font-size: 16px;
        color: #6DCDF6;
        text-decoration: underline;
      }
      &__days {
        font-size: 16px;
        color: #878787;
      }
      &__days:before {
        content: '';
        margin: 5px;
      }
    }
  }
}
.week-regular:hover {
  transition-duration: 1s;
  background: #6DCDF6;
  cursor: pointer;
}
.week-past {
  background: #F3F3F3;
}
.week-past:hover {
  transition-duration: 1s;
  background: #e5e4e4;
  cursor: pointer;
}