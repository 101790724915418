.container {

    height: 100vh;
    padding: 0 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header {
    font-size: 46px;
}

.description {
    color: #EEEEEE;
}
