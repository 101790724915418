.accept-holiday {
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 1160px;
    height: 696px;
    background: #FFFFFF;
    thead > tr {
      box-shadow: inset 0px -1px 0px #EEEEEE;
    }
  }
  &__header {
    padding: 40px 98px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    height: 140px;
    & > h3 {
      font-weight: 400;
      font-size: 28px;
      line-height: 30px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }
    & > p {
      font-weight: 300;
      font-size: 14px;
      line-height: 19px;
      text-transform: uppercase;
    }
  }
  &__table-wrapper {
    padding: 0 98px;
    height: 402px;
    //overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
    & > table {
      width: 964px;
      //box-shadow: inset 0px -1px 0px #EEEEEE;
      & .ah-modal-name,
      & .ah-modal-dates,
      & .ah-modal-status {
        height: 64px;
        font-weight: 450;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #6D6E71;
      }
      & .ah-modal-name {
        width: 484px;
      }
      & .ah-modal-dates {
        width: 214px;
      }
      & .ah-modal-status {
        width: 266px;
      }
    }
  }
  &__controls {
    display: flex;
    align-items: center;
    padding: 0 98px;
    height: 153px;
  }
}