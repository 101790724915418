.application-filter {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 570px;
  max-height: 530px;
  //background: lavender;
  background: #FFFFFF;
  -webkit-box-shadow: 1px 2px 13px -3px rgba(34, 60, 80, 0.41);
  -moz-box-shadow: 1px 2px 13px -3px rgba(34, 60, 80, 0.41);
  box-shadow: 1px 2px 13px -3px rgba(34, 60, 80, 0.41);
  gap: 10px;
  &__input {
    position: relative;
    & img {
      z-index: 1;
      height: 16px;
      width: 16px;
      display: block;
      position: absolute;
      left: 15px;
      top: 12px
    }
  }
  &__input > input {
    width: 560px;
    height: 28px;
    border: 1px solid rgba(151, 153, 155, 0.25);
    padding: 6px 7px;
    margin: 6px;
    transition-duration: 1s;
    outline: 1px solid rgba(151, 153, 155, 0.25);
    cursor: default;
    &:focus {
      cursor: text;
      transition-duration: 1s;
      outline: solid 1px #6DCDF6;
    }
  }
  &__employee-list {
    width: 560px;
    height: 240px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style-type: none;
    &__item {
      display: flex;
      gap: 6px;
      padding: 12px 13px;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      color: #767676;
      &:hover {
        cursor: pointer;
        background: #FAFAFA;
        color: #6DCDF6;
      }
    }
  }
  &__selected-employees {
    width: 560px;
    max-height: 140px;
    overflow: auto;
    background: #FAFAFA;
    &__reset {
      font-size: 12px;
      font-style: normal;
      line-height: 14px;
      width: 160px;
      cursor: pointer;
      font-weight: 500;
      margin: 10px 24px;
      color: #8ad7f6;
      transition-duration: .5s;
      &:hover {
        transition-duration: .5s;
        color: #6DCDF6;
      }
      &:active {
        transition-duration: .5s;
        color: #0daaf5;
      }
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      padding: 10px;
      margin: 0;
      list-style-type: none;
      &__item {
        display: flex;
        align-items: center;
        background: #F3F3F3;
      }
      &__item > div {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        padding: 8px;
        margin: 0 5px 0 5px;
        word-break: break-word;
      }
      &__item > img {
        height: 20px;
      }
    }
  }
  &__controll {
    height: 45px;
    width: 560px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & div {
      font-size: 14px;
      font-style: normal;
      line-height: 21px;
      letter-spacing: 2.1px;
      text-transform: uppercase;
      font-weight: 500;
      padding: 0 55px;
      transition-duration: 1s;
      cursor: pointer;
      &:hover {
        transition-duration: 1s;
        color: #6DCDF6;
      }
    }
  }
}