.container-forbidden {
  animation-name: fadeId;
  animation-duration: 5s;
  margin: 25vh auto;
  width: 1160px;
  &__paragraph {
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    &__closures {
      color: #b5b5b5;
      font-size: 26px;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}