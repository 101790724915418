.delegation-in-progress-item {
  padding: 12px 13px;
  min-height: 57px;
  transition-duration: .5s;
  &:hover {
    background: whitesmoke;
  }
  &__fullname,
  &__info,
  &__info__dates {
    color: #767676;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
  &__info,
  &__info__dates {
    display: flex;
    gap: 5px;
  }
}