@import "src/styles/styles";

.simple-dropdown-default {
  width: 100%;
  &__item {
    padding: 13px 12px;
    display: flex;
    align-items: center;
    min-height: 28px;
    color: $gray-primary;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: 14px;
    cursor: pointer;
    transition-duration: 1s;
    &:hover {
      background: $stroke-light-1;
      color: $blue-light-primary;
      transition-duration: 1s;
    }
  }
  &__empty-item {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 1s;
    & > div {
      height: 14px;
      width: 90%;
      background: $stroke-light-0;;
      border-radius: 5px;
    }
    &:hover {
      transition-duration: 1s;
      background: $stroke-light-2;
    }
  }
}