.forgot-modal__input-invalid,
.forgot-modal__input{
    width: 300px;
    height: 40px;
    background: #fff;
    border: none;
    font-weight: 450;
    padding:0 15px;
    text-align: center;
}
.forgot-modal {
    flex-direction: column;
    padding: 20px;
    gap: 30px;
    width: 844px;
    height: 260px;
    display: flex;
    align-items: center;
    text-align: center;
    &__header {
        font-weight: 400;
        font-size: 21px;
        line-height: 135%;
    }
    &__header-invalid {
        font-weight: 400;
        font-size: 21px;
        line-height: 135%;
        color: #ED1C24;
    }
    &__input {
        border-bottom: 1px solid rgba(238, 238, 238, 1);
        &::placeholder {
            text-align: center;
        }
    }
    &__input-invalid {
        border-bottom: 1px solid rgba(237, 28, 36, 1) ;
    }
    &__buttons {
        position: absolute;
        display: flex;
        padding: 15px 62px;
        bottom: 0;
        gap:55px;
        &__button {
            width: 275px;
            height: 41px;
            border: 2px solid #6DCDF6;
            border-radius: 42px;
            background: #FFFFFF;
            font-weight: 450;
            letter-spacing: 0.15em;
            transition-duration: 1s;
            text-transform: uppercase;
            margin-bottom: 7px;
            &:hover {
                cursor: pointer;
                background: #f7fafd;
                transition-duration: 1s;
                transform: scale(1.01);
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            }
            &:disabled {
                background: rgba(245, 245, 245, 1);
                border: none;
            }
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type='number'] {
    -moz-appearance: textfield;
}
