.signType-modal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin: auto;
  //width: 40vw;
  background: white;
  padding: 30px 40px;
  text-align: center;

  &__text{
    width: 40vw;

    font-size: 21px;
    font-weight: 400;
  }
  &__button{
    width: 275px;
    height: 41px;
    border: 2px solid #6DCDF6;
    border-radius: 42px;
    background: #FFFFFF;
    font-weight: 450;
    letter-spacing: 0.15em;
    transition-duration: 1s;
    text-transform: uppercase;
    margin-bottom: 7px;
    &:hover {
      cursor: pointer;
      background: #6DCDF6;
      transition-duration: 1s;
      color: whitesmoke;
      transform: scale(1.01);
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    &:disabled {
      background: rgba(245, 245, 245, 1);
      border: none;
    }
  }
}
