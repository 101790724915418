@import url('../../../styles/default/_variables.scss');
.start-page__button,
.start-page__text{
  text-transform: uppercase;
}
.start-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100vw;
  height: 100vh;
  gap: 30px;
   &__logo {
     width:324px;
   }
  &__text {
    width: 384px;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.2em;
    text-align: center;

  }
  &__button{
    width: 275px;
    height: 41px;
    border: 2px solid #6DCDF6;
    border-radius: 42px;
    background: #FFFFFF;
    margin-bottom: 150px;
    font-weight: 450;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.15em;
    transition-duration: 1s;
    &:hover {
      cursor: pointer;
      background: #f7fafd;
      transition-duration: 1s;
      transform: scale(1.01);
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
  }
}



