.personal-allowance {
    width: 1160px;
    margin: 0 auto;
    &__legend {
        font-size: 11px;
        color: #97999B;
        margin-bottom: 36px;
    }
    &__form {
        display: flex;
        position: relative;
        width: 1160px;
        margin: 0 auto;
        &__header-left {
            display: flex;
            position: sticky;
            border-right: .5px solid black;
            box-shadow: 0px -1px 0px 0px #EEEEEE inset;
            height: 96px;
            font-size: 12px;
            line-height: 16.2px;
            text-transform: uppercase;
            color: #6D6E71;
            padding: 14px;
            padding-left: 0;
            &__struct {
                display: flex;
                align-items: center;
                width: 240px;
            }
            &__employee {
                display: flex;
                align-items: center;
                width: 180px;
            }
        }
        &__header-right {
            position: sticky;
            display: grid;
            grid-template-columns: repeat(13, 1fr);
            left: 420px;
            width: 740px;
            overflow: auto;
            div {
                display: flex;
                align-items: center;height: 96px;
                box-shadow: 0px -1px 0px 0px #EEEEEE inset;
                font-size: 12px;
                line-height: 16.2px;
                text-transform: uppercase;
                color: #6D6E71;
                padding: 28px;
                padding-left: 0;
            }
            & div:nth-child(1) {
                width: 182px;
            }
            & div:nth-child(2) {
                width: 82px;
            }
            & div:nth-child(3) {
                width: 108px;
            }
            & div:nth-child(4) {
                width: 180px;
            }
            & div:nth-child(5) {
                width: 145px;
            }
            & div:nth-child(6) {
                width: 145px;
            }
            & div:nth-child(7) {
                width: 112px;
            }
            & div:nth-child(8) {
                width: 175px;
            }
            & div:nth-child(9) {
                width: 210px;
            }
            & div:nth-child(10) {
                width: 175px;
            }
            & div:nth-child(11) {
                width: 175px;
            }
            & div:nth-child(12) {
                width: 195px;
            }
            & div:nth-child(13) {
                width: 195px;
            }
        }
    }
    
}