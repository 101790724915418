.hp-table-year {
  border-collapse: collapse;
  width: 570px;
  margin: 30px auto;

  & tbody tr {
    box-shadow: inset 0px -1px 0px #EEEEEE;
  }

  &__head {
    height: 80px;
    box-shadow: inset 0px -1px 0px #EEEEEE;

    &__department-year,
    &__worker-year,
    &__vacation-days-year {
      vertical-align: center;
      text-align: left;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #6D6E71;
      word-break: break-word;
    }

    &__department-year,
    &__worker-year,
    &__vacation-days-year {
      width: 160px;
    }
  }

  &__body {
    &__department > div,
    &__worker > div,
    &__vacation-days div {
      position: relative;
      height: 80px;
      text-align: left;
      vertical-align: center;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      //padding: 5px;
      overflow: hidden;
      &:hover {
        overflow: visible;

      }
    }

    // TODO REFACTOR

    &__department > div > div,
    &__worker > div > div {
      transition-duration: .5s;
      cursor: default;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        z-index: 2;
        transition-duration: .2s;
        background: #f6f6f6;
        opacity: 90%;
        overflow: visible;
      }
    }
    &__department > div > div {
      width: 160px;
      height: 80px;
      &:hover {
        //position: absolute;
        height: initial;
        //min-height: 80px;
      }
    }

    &__department > div,
    &__worker > div,
    &__vacation-days div {
      cursor: default;

      width: 160px;
      display: flex;
      align-items: center;
    }
  }
}

.table-year-container {
  width: 1160px;
  margin: auto;
  display: flex;
}

.internal-table {
  display: block;
  white-space: nowrap;
  width: 710px;
  overflow-x: auto;
  border-collapse: collapse;
  margin-top: 30px;

  & tbody tr {
    box-shadow: inset 0px -1px 0px #EEEEEE;
  }

  & thead,
  & tbody {
    display: table;
    width: 935px;
  }

  &__head {
    height: 80px;
    box-shadow: inset 0px -1px 0px #EEEEEE;

    &__department-year,
    &__worker-year,
    &__vacation-days-year,
    &__table-header-month {
      vertical-align: center;
      text-align: left;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #6D6E71;
      word-break: break-word;
    }

    &__table-header-month {
      letter-spacing: normal;
      text-transform: none;
      width: 75px;
    }
  }

  &__body {
    &__month {
      height: 80px;
      width: 75px;
    }
  }
}

.internal-table:hover {
  overflow-x: auto;
}

.internal-table::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 1);
}

.month-regular,
.month-past {
  transition-duration: 1s;
  position: relative;
  width: 100%;
  height: 100%;
  background: #E0F4FF;
  border-bottom: .5px solid #EEEEEE;
  border-top: .5px solid #EEEEEE;

  &__pop-up {
    z-index: 100;
    display: none;
    position: fixed;
    transform: translate(30px, 83px);

    min-height: 50px;
    min-width: 70px;
    background: #FFFFFF;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    &__list {
      list-style: none;
      padding: 20px 30px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      &__dates {
        font-size: 16px;
        color: #6DCDF6;
        text-decoration: underline;
      }

      &__days {
        font-size: 16px;
        color: #878787;
      }

      &__days:before {
        content: '';
        margin: 5px;
      }
    }
  }
}

.month-regular:hover {
  transition-duration: 1s;
  background: #6DCDF6;
  cursor: pointer;
}

.month-past {
  background: #F3F3F3;
}

.month-past:hover {
  transition-duration: 1s;
  background: #e5e4e4;
  cursor: pointer;
}

.month-regular:hover .month-regular__pop-up,
.month-past:hover .month-regular__pop-up {
  display: block;
}