.container{
  display: flex;
  max-height: 450px;
}

.header-container{
  position: sticky;
  top: 0;
  display: flex;
  row-gap: 48px;
  padding: 40px 90px;
  z-index: 30;
  background-color: white;
  flex-direction: column;
  width: 100%;
}
