.maternity-leave {
  width: 1160px;
  margin:62px auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  &__dateControls {
    display: flex;
    margin-top: 20px;
    gap: 20px;
  }
  &__notification {
    width: 600px;
    font-weight: 450;
    font-size: 12px;
    line-height: 135%;
  }
  &-comment {
    min-height: 40px;
    border: none;
    border-bottom: #EEEEEE 1px solid;
    &__container {
      display: flex;
      flex-direction: column;
    }
    &__label {
      font-size: 12px;
      line-height: 14px;
    }
    &__underline {
      color: #97999B;
      margin-top: 6px;
      font-size: 11px;
      line-height: 15px;
    }

  }
  &-commentError {
    min-height: 40px;
    border: none;
    border-bottom: #ED1C24 1px solid;
  }
}
