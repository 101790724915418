.pagination-control {
  margin: 10px auto;
  width: 1160px;
  display: flex;
  justify-content: flex-end;
  //align-content: flex-end;
  gap: 150px;
  cursor: default;

  &__pages-paginate,
  &__per-page-paginate {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    &__item,
    &__item-break,
    &__item-prev,
    &__item-next {
      font-weight: 400;
      font-size: 10px;
      line-height: 140%;
      transition-duration: .5s;
      min-width: 20px;
      text-align: center;
      &:hover {
        cursor: pointer;
        transition-duration: .5s;
        color: #c0c2c5;
      }
      &:active {
        color: #3f4345;
      }
      color: #6D6E71;
    }
    &__item-prev img,
    &__item-next img,
    &__item-prev-lock img,
    &__item-next-lock img
    {
      margin-top: 2px;
    }
    &__item-prev-lock,
    &__item-next-lock {
      color: #c0c2c5;
      cursor: not-allowed;
    }
    &__item-selected {
      font-weight: 500;
      font-size: 10px;
      line-height: 140%;
      color: #000000;
      text-shadow: #727272 .5px .5px 10px;
      min-width: 20px;
      text-align: center;
    }
  }

  &__pages-paginate {
    min-width: 300px;
    justify-content: flex-end;
  }
  &__per-page-paginate {
    justify-self: center;
    align-self: center;
    gap: 20px;
  }
}