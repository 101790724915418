
.login-container-terminal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .login-wrapper-terminal {
    display: flex;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    row-gap: 54px;
    padding: 54px;
  }
}