.cross-table-controller {
  width: 1160px;
  margin: 30px auto;
  display: flex;
  justify-content: space-between;


  // LEGEND STYLES
  &__legend {
    display: flex;
    list-style: none;
    padding: 0;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    color: #6D6E71;
    gap: 20px;
    & > li {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  // CONTAINER WITH YEAR AND MONTHS
  &__period-control-wrapper {
    width: 1160px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    gap: 20px;
  }

  // CONTAINER WITH YEAR AND ARROWS
  &__year-with-control {
    position: relative;
    width: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__bubble__left,
    &__bubble__right {
      top: -10px;
      left: -10px;
      background: red;
      border: 1px solid red;
      border-radius: 100%;
      width: 10px;
      height: 10px;
      margin: 2.5% auto;
      position: absolute;
      overflow:hidden;
      -webkit-animation: pulse 4s infinite;
      animation: pulse 4s infinite;
      > span {
        display: block;
        //position: absolute;
        background: pink;
        border-radius: 100%;
        height: 8px;
        width: 8px;
        transform: scale(0);
        -webkit-animation: ping 2s infinite;
        animation: ping 2s infinite;
      }
    }
    &__bubble__right {
      top: -10px;
      left: 90px;
    }
    &__selected-year {
      font-size: 14px;
      line-height: 20px;
      cursor: default;
    }
  }
  // ARROWS
  &__left-arrow,
  &__right-arrow,
  &__left-arrow-disabled,
  &__right-arrow-disabled {
    transform: rotate(135deg);
    width: 12px;
    height: 12px;
    border: 1px solid #0E0E0F;
    cursor: pointer;
  }
  &__left-arrow-disabled,
  &__right-arrow-disabled {
    border: 1px solid #c1cbd5;
    cursor: not-allowed;
  }
  &__left-arrow,
  &__left-arrow-disabled {
    border-top: none;
    border-left: none;
  }
  &__right-arrow,
  &__right-arrow-disabled {
    border-bottom: none;
    border-right: none;
  }

  // MONTHS STYLES AND BEHAVIOR
  &__past-month {
    font-size: 12px;
    line-height: 14px;
    text-transform: capitalize;
    transition-duration: .5s;
    cursor: default;
    color: #818C99;
    &:hover {
      cursor: pointer;
      transition-duration: .5s;
      color: #586274;
    }
  }
  &__selected-month {
    font-size: 12px;
    line-height: 14px;
    text-transform: capitalize;
    transition-duration: .5s;
    cursor: default;
    color: #6DCDF6;
  }
  &__month {
    font-size: 12px;
    line-height: 14px;
    text-transform: capitalize;
    transition-duration: .5s;
    cursor: default;
    &:hover {
      cursor: pointer;
      transition-duration: .5s;
      color: #818C99;
    }
    &:active {
      color: #0E0E0F;
    }
  }
}
// LEGEND BEFORE BLOCKS COLORING
li[list-item='past']:before,
li[list-item='cross']:before,
li[list-item='regular']:before,
li[list-item='over']:before{
  display: inline-block;
  content: '';
  width: 25px;
  height: 14px;
  margin-right: 5px;
}
li[list-item='past']:before {
  background: #F3F3F3;
}
li[list-item='cross']:before {
  background: #FFDDC5;
}
li[list-item='regular']:before {
  background: #CDE8F5;
}
li[list-item='over']:before {
  background: #FFC3C3;
}



@-webkit-keyframes pulse {
  50% {-webkit-transform: scale(1.1);}
  100% {-webkit-transform: scale(1);}
}
@keyframes pulse {
  50% {transform: scale(1.1);}
  100% {transform: scale(1);}
}

@-webkit-keyframes ping {
  100% {opacity: 0; -webkit-transform: scale(2);}
}
@keyframes ping {
  100% {opacity: 0; transform: scale(2);}
}