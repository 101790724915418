//@mixin animation-mixin($name, $from, $to, $max-height) {
//  @keyframes #{$name} {
//    0% {
//      height: $from;
//    }
//    100% {
//      height: $to;
//    }
//  }
//}
//@include animation-mixin(dropdownAppear, 0, 200px, 200px);


.dropdown-lists {
  z-index: 5;
  position: relative;
  max-height: 40px;
  min-width: 370px;

  &-container {
    width: 1160px;
    margin: auto;
  }

  &__label {
    margin: 0 auto 10px auto;
    font-weight: 400;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }

  &__select-hidden,
  &__select-active {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 30px;
    width: 100%;
    height: 100%;
    box-shadow: rgba(149, 157, 165, .2) 0px 8px 24px;
  }

  &__select {
    &__selected {
      cursor: default;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }

    &__arrow {
      display: flex;
      align-items: center;
    }

    &__arrow-down {
      cursor: default;
      width: 30px;
      height: 30px;
      transform: rotateX(180deg);
      transition-duration: .5s;
    }

    &__arrow-up {
      cursor: default;
      width: 30px;
      height: 30px;
      transform: rotateX(0deg);
      transition-duration: .5s;
    }
  }

  &__options-active {
    display: block;
    width: 100%;
    background: #FFFFFF;
    position: absolute;
    max-height: 200px;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    list-style: none;
    box-shadow: rgba(149, 157, 165, .2) 0px 8px 24px;
    //animation: dropdownAppear 1s;
  }

  &__options-hidden {
    display: none;
  }

  &__item,
  &__empty-item {
    background: #FFFFFF;
    min-height: 40px;
    padding: 18px 30px;
    transition-duration: 1s;

    &__text {
      cursor: default;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }

    &:hover {
      background: #EEEEEE;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    color: #97999B;
  }

  &__empty-item {
    border-bottom: .5px solid #EEEEEE;
  }

  &__empty-item:after {
    content: "";
    width: 300px;
    height: 40px;
    display: block;
    //background: #EEEEEE;
    border-radius: 5px;
    animation: placeholderShimmer 2s infinite;
  }

}

.dropdown-documentLists {
  z-index: 5;
  position: relative;
  max-height: 30px;
  min-width: 320px;

  &-container {
    width: 1160px;
    margin: auto;
  }

  &__label {
    margin: 0 auto 10px auto;
    font-weight: 400;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }

  &__select-hidden,
  &__select-active {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 30px;
    width: 100%;
    height: 100%;
    box-shadow: rgba(149, 157, 165, .2) 0px 4px 20px;
  }

  &__select {
    &__selected {
      cursor: default;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }

    &__arrow {
      display: flex;
      align-items: center;
    }

    &__arrow-down {
      cursor: default;
      width: 30px;
      height: 30px;
      transform: rotateX(180deg);
      transition-duration: .5s;
    }

    &__arrow-up {
      cursor: default;
      width: 30px;
      height: 30px;
      transform: rotateX(0deg);
      transition-duration: .5s;
    }
  }

  &__options-active {
    display: block;
    width: 100%;
    background: #FFFFFF;
    position: absolute;
    max-height: 200px;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    list-style: none;
    box-shadow: rgba(149, 157, 165, .2) 0px 3px 24px;
    //animation: dropdownAppear 1s;
  }

  &__options-hidden {
    display: none;
  }

  &__item,
  &__empty-item {
    background: #FFFFFF;
    min-height: 40px;
    padding: 18px 30px;
    transition-duration: 1s;

    &__text {
      cursor: default;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }

    &:hover {
      background: #EEEEEE;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    color: #97999B;
  }

  &__empty-item {
    border-bottom: .5px solid #EEEEEE;
  }

  &__empty-item:after {
    content: "";
    width: 300px;
    height: 40px;
    display: block;
    //background: #EEEEEE;
    border-radius: 5px;
    animation: placeholderShimmer 2s infinite;
  }
}

@keyframes dropdownAppear {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(200px);
  }
}

//sceleton animation
@keyframes placeholderShimmer {
  0% {
    background: #FFFFFF;
  }
  50% {
    background: #EEEEEE;
  }
  100% {
    background: #FFFFFF;
  }
}
