.information-modal {
  position: relative;
  width: 1160px;
  background: white;
  min-height: 300px;

  &__error-message {
    padding: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;

    &__error {
      font-size: 34px;
      text-align: center;
      align-self: center;
      color: #97999B;
    }

    &__button {
      width: 275px;
      height: 41px;
      border: 2px solid #6DCDF6;
      border-radius: 42px;
      background: #FFFFFF;
      font-weight: 450;
      letter-spacing: 0.15em;
      transition-duration: 1s;
      text-transform: uppercase;
      margin-bottom: 7px;

      &:hover {
        cursor: pointer;
        color: white;
        background: rgba(109, 205, 246, 1);
        transition-duration: 1s;
        transform: scale(1.01);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }

      &:disabled {
        background: rgba(245, 245, 245, 1);
        border: none;
      }
    }
  }

  &__modal-union {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}

.information {
  padding: 60px 80px;

  &__header {
    font-weight: 450;
    font-size: 27px;
    line-height: 30px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin-bottom: 25px;
  }

  &__lines {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 50vh;
  }

  &__item {
    min-height: 50px;
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    align-items: center;

    &__label {
      padding: 16px 0;
    }

    :first-child {
      width: 430px;
      font-weight: 450;
      font-size: 12px;
      line-height: 135%;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #6D6E71;
    }

    :last-child {
      font-weight: 400;
      font-size: 14px;
      line-height: 135%;
      letter-spacing: normal;
      color: #000000;
      width: 560px;
      text-transform: none;
    }
  }
}

.work-exp__item {
  display: flex;
  flex-direction: row;
  gap: 5px;

  &__text {
    max-width: 165px;
  }

  &__block {
    width: 25px;
    height: 14px;
  }
}
