.container {
  padding: 16px 32px;
  cursor: pointer;
  font-weight: 450;
  border: none;
  font-size: 20px;
  border-radius: 40px;
  background-color: #007ac2;
  color: white;
  width: 100%;
}

.container:disabled {
  background-color: #d7d7d7;
  color: #97999b;
}

.text {
  display: flex;
  border: none;
  background: none;
  width: fit-content;
  color: #007ac2;
  cursor: pointer;
  font-size: 18px;
}

.text-start {
  align-self: flex-start;
}

.text-center {
  align-self: center;
}

.text-end {
  align-self: flex-end;
}

.text:disabled {
  color: #97999b;
}

.outlined {
  padding: 16px 32px;
  font-size: 20px;
  font-weight: 450;
  width: 100%;
  cursor: pointer;
  border: 2px solid #007ac2;
  color: #212529;
  background-color: inherit;
  border-radius: 40px;
}

.outlined:disabled {
  border: 2px solid #97999b;
  color: #97999b;
}
