.hint {
  position: relative;
  display: flex;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  &__modal {

    width: 450px;
    min-height: 150px;

    position: absolute;
    top: 0;
    //top: 50%;
    bottom: 0;
    //left: 0;
    left: -225px;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
  }

  &__modal-content {
    width: 450px;
    min-height: 150px;
    background: #ffffff;
    padding: 30px 50px 30px 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  &__content {
  }

  &__cross {
    position: absolute;
    right: 26px;
    top: 26px;
    width: 16px;
    height: 16px;
    opacity: 0.3;
    transition-duration: .5s;

    &:hover {
      transition-duration: .5s;
      opacity: 1;
    }

    &:before, &:after {
      position: absolute;
      //left: 15px;
      content: ' ';
      height: 20px;
      width: 1px;
      background-color: #333;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}