.request-modal-woh {
  margin: 0 auto;

  .employees-list-items {
    width: 100%;

    .request-modal__table__line {
      box-shadow: none;
      display: flex;
      align-items: stretch;
      padding-bottom: 0;

      .right-column {
        box-shadow: inset 0px -1px 0px #EEEEEE;
        padding-bottom: 20px;
      }
    }
  }

  .request-modal__header {
    font-weight: 450;
    font-size: 26px;
    line-height: 42px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #000000;
  }

  .request-modal__table__line {
    align-items: start;
  }

  .left-column {
    min-width: 396px;
    flex: 1;
    letter-spacing: 0.2em;
  }

  .right-column {
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 135%;
    color: #6D6E71;
    flex: 1;

  }

  .employees-list-empty {
    width: 100%;
    text-align: center;
    margin-top: 16px;
    color: #ED1C24;
  }

  .employer-name {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #000000;
    margin-right: 16px;
  }

  .employer-checkbox {
    margin-right: 8px;
    height: 16px;
    width: 16px;
    float: left;
  }

  .employer-tabel, .employer-grade {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: #000000;
    text-transform: none;
    letter-spacing: normal;
    margin-top: 4px;
  }

  .employee-request-status {
    font-weight: 500;
    font-size: 12px;
    //color: black;
  }

  .employee-request_status__confirmed {
    .employee-request-status {
      color: green;
    }
  }

  .employee-request_status__not-confirmed {
    .employer-name, .employer-grade, .employer-tabel {
      color: #97999B;

    }

    .employee-request-status {
      color: #ED1C24;
    }
  }

  .employee-request_status__rejected {

    .employer-name, .employer-grade, .employer-tabel {
      color: #97999B;
    }

    .employee-request-status {
      color: #ED1C24;
    }

  }

}

.green {
  color: green;
}

.red {
  color: #ED1C24;
}

.black {
  color: black;
}
