.custom-uploader {
  &__item {
    display: flex;
    gap: .5em;
    position: relative;
    align-items: center;

    &__name {
      color: #6DCDF6;

      &:active {
        color: #6DCDF6;
      }

      &:focus {
        color: #6DCDF6;
      }

      &:hover {
        color: #6DCDF6;
      }
    }

    &__link {
      a div {
        display: none;
        animation: fadeOut .3s;
      }

      a:hover div {
        animation: fadeIn .3s;
        display: block;
        position: absolute;
        border: 1px solid #6DCDF6;
        padding: 5px;
        background: #FFFFFF;
        top: -30px;
        border-radius: 5px;
        font-size: 12px;
        line-height: 14px;
      }


      a:hover div::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;

        border-top: 8px solid transparent;
        border-bottom: 8px solid #6DCDF6;
        border-right: 8px solid transparent;
        left: -1.5px;

        top: 9px;
      }
    }

    &__delete {
      height: 32px;
      cursor: pointer;
      transition-duration: 1s;

      &:hover {
        transition-duration: 1s;
        transform: scale(1.05);
        filter: grayscale(1);
      }

      &:active {
        transform: scale(1);
      }
    }
  }

}

@keyframes fadeIn {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}
