.container{
    display: flex;
    max-height: 450px;
}

.image-header {
    position: relative;
    height: 144px;
    width: 1160px;
    cursor: pointer;
    transition-duration: .5s;
}

.image-header:hover {
    transition-duration: .5s;
    transform: scale(.9994);
    opacity: 95%;
    filter: sepia(15%) brightness(90%) saturate(174%) opacity(95%) contrast(120%);
    -webkit-filter: sepia(15%) brightness(90%) saturate(174%) opacity(95%) contrast(120%);
    -moz-filter: sepia(15%) brightness(90%) saturate(174%) opacity(95%) contrast(120%);
}

.image-header:focus,
.image-header:active {
    transition-duration: .5s;
    opacity: 98%;
    transform: scale(.99);
    -webkit-box-shadow: 0px 0px 94px -39px rgba(66, 191, 236, 0.5);
    -moz-box-shadow: 0px 0px 94px -39px rgba(66, 191, 236, 0.5);
    box-shadow: 0px 0px 94px -39px rgba(66, 191, 236, 0.5);
}

.header-container{
    width: 1160px;
    margin: 0 auto;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
}



