
.containerInput {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.inputWrapper {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}

.inputWrapperError {
  border-bottom: 1px solid rgba(237, 28, 36, 1);
}

.inputWrapperDefault {
  border-bottom: 1px solid rgba(238, 238, 238, 1);
}

.input {
  border: none;
  width: 80%;
  line-height: 20px;
}

.wrapperEye {
  display: flex;
  justify-content: left;
  cursor: pointer;
  padding: 0 5px;
}

.errorView {
  color: #ed1c24;
}

.coloredTexts {
  font-size: 18px;
  color: #007ac2;
}