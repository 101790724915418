@import "/src/styles/styles";

.single-uploader {
  display: flex;
  flex-direction: column;
  //align-items: center;
  gap: 10px;
  color: #818C99;
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;

  &__upload-field {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #818C99;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;

    &__text {
      color: rgba(0, 0, 0, 1);
      font-size: 12px;
      line-height: 14px;
      width: 580px;

    }

    &__input {
      display: flex;
      flex-direction: row;
      gap: 10px;

      &__line {
        color: black;
        width: 580px;
        min-height: 40px;
        border-bottom: 1px solid #EEEEEE;
      }

      &__line-red {
        color: black;
        width: 580px;
        min-height: 40px;
        border-bottom: 1px solid $red-primary;
      }
    }

  }

  &__img {
    transition-duration: 1s;

    &:hover {
      transition-duration: 1s;
      transform: scale(1.05);
      filter: grayscale(1);
      cursor: pointer;
    }
  }

  &__input {
    min-height: 0;
    opacity: 0;
    width: 0;
    height: 0;
    line-height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: flex;
    gap: .5em;
    position: relative;
    align-items: center;

    &__name {
      color: #6DCDF6;

      &:active {
        color: #6DCDF6;
      }

      &:focus {
        color: #6DCDF6;
      }

      &:hover {
        color: #6DCDF6;
      }
    }

    &__delete {
      height: 32px;
      cursor: pointer;
      transition-duration: 1s;

      &:hover {
        transition-duration: 1s;
        transform: scale(1.05);
        filter: grayscale(1);
      }

      &:active {
        transform: scale(1);
      }
    }
  }

}
