.parental-leave {
  width: 1160px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;

  &__tabs {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;

    &__tab {
      height: 70px;
      box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.06);
      display: flex;
      flex-wrap: wrap;
      width: fit-content;
      //min-width: 215px;
      padding: 0 15px;


      &:hover {
        transition-duration: 1s;
        transform: scale(1.04);
        cursor: pointer;
        box-shadow: 10px 15px 25px 10px rgba(0, 0, 0, 0.06);
      }

      &__tittle {
        font-size: 18px;
        line-height: 22.5px;
        margin: auto 5px auto auto;
        text-align: center;
      }

    }

    &__active-tab {
      height: 70px;
      box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.06);
      display: flex;
      flex-wrap: wrap;
      width: fit-content;
      padding: 0 15px;
      border: 3px solid rgba(109, 205, 246, 1);

      &:hover {
        transition-duration: 1s;
        transform: scale(1.04);
        cursor: pointer;
        box-shadow: 10px 15px 25px 10px rgba(0, 0, 0, 0.06);
      }

      &__tittle {
        font-size: 18px;
        line-height: 22.5px;
        margin: auto 5px auto auto;
        text-align: center;
      }
    }


  }

  &__visible {
    display: none;
  }

  &-general-information {
    width: 1160px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
  }

  &__helper-input {
    min-height: 40px;
    border: none;
    border-bottom: #EEEEEE 1px solid;

    &__container {
      display: flex;
      flex-direction: column;
    }

    &__label {
      font-size: 12px;
      line-height: 14px;
    }

    &__underline {
      color: #97999B;
      margin-top: 6px;
      font-size: 11px;
      line-height: 15px;
    }
  }

  &__notification {
    width: 600px;
    font-weight: 450;
    font-size: 12px;
    line-height: 135%;
  }

  &-comment {
    min-height: 40px;
    border: none;
    border-bottom: #EEEEEE 1px solid;

    &__container {
      display: flex;
      flex-direction: column;
    }

    &__label {
      font-size: 12px;
      line-height: 14px;
    }

    &__underline {
      color: #97999B;
      margin-top: 6px;
      font-size: 11px;
      line-height: 15px;
    }

  }

  &-commentError {
    min-height: 40px;
    border: none;
    border-bottom: #ED1C24 1px solid;
  }
}

