.modal-support-container{
    display: flex ;
    flex-direction: column ;
    row-gap: 50px ;
    padding: 30px;
    width: fit-content;
    background-color: #FFF ;
    text-align: center ;
    font-size: 16px ;
}

.show-modal-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 20px;
}

.show-modal-text > div {
    max-width: 600px;
}

.header-support {
    font-family: 'Avenir Next Cyr';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 135%;
    margin-bottom: 2vh;
}

.big-button-support-container{
    display: flex;
    align-self: center;
}

.big-button-support{
    display: flex;
    align-items: center;
    border: 2px solid rgba(0, 122, 194, 1);
    padding: 10px 25px;
    border-radius: 42px;
    background: none;
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
    color: black;
    letter-spacing: 0.15em;
}

.big-button-support:hover {
    cursor: pointer;
}
