.application-details {
  &__close-cross {
    position: absolute;
    top: 30px;
    left: 1100px;
    cursor: pointer;
    transition-duration: .5s;
    &:hover {
      transition-duration: .5s;
      background: #6D6E71;
      border-radius: 50%;
      filter: invert(1);
    }
    &:active {
      background: #1f2121;
    }
  }
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1160px;
    min-height: 896px;
    padding: 90px 100px;
    margin: auto;
    background: #FFFFFF;
    box-shadow: #888888 .5px .5px 20px;
    gap: 25px;
  }
  &__header {
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 5.6px;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    color: #000000;
  }
  &__controlls {
    align-items: center;
    display: flex;
    max-width: 500px;
    justify-content: space-between;
    gap: 30px;
    &-prev,
    &-next {
      cursor: pointer;
      transition-duration: .5s;

      &:hover {
        transition-duration: .5s;
        background: #6D6E71;
        border-radius: 50%;
        filter: invert(1);
      }
      &:active {
        background: #1f2121;
      }
    }
    &-prev-disabled,
    &-next-disabled {
      filter: opacity(30%);
      cursor: not-allowed;
    }
  }
  &__name {
    font-size: 26px;
    font-weight: 500;
    line-height: 42px;
    color: #000000;
  }
  &__order-number {
    width: 300px;
    color: #121212;
    text-align: center;
    font-size: 21px;
    line-height: 135%;
  }
  &__table-wrapper {
    & > table {
      width: 960px;
      border-collapse: collapse;
      & tr {
        height: 58px;
        box-shadow: 0px -1px 0px 0px #EEEEEE inset;
      }
    }
  }
  &__table-col-name {
    width: 230px;
    color: var(--a-grey, #6D6E71);
    font-size: 12px;
    line-height: 135%;
    letter-spacing: 2.4px;
    text-transform: uppercase;
  }
  &__table-col-data,
  &__table-col-data-files {
    color: var(--a-black, #000);
    font-size: 14px;
    line-height: 135%;
  }
  &__table-col-data-files {
    width: 730px;
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    gap: 5px;
    flex-wrap: wrap;
    & div {
      cursor: default;
      color: #6DCDF6;
      margin: 2px;
      padding: 5px;
      border-radius: 5px;
      border: .3px solid #6DCDF6;
      transition-duration: 1.5s;
      &:hover {
        cursor: pointer;
        transition-duration: 1.5s;
        color: #EEEEEE;
        background: #1ea7fd;
      }
    }
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
  }
}