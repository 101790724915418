.reset-password__container{
    display: flex;
    overflow: hidden;
    width: 100%;
    /*height: 780px;*/
    /*min-height:780px;*/
}

.wrapper{
    /*width: 632px;*/
    width: 43%;
    min-width: fit-content;
    /*height: 632px;*/
}
.img-auth{

}
.authPhoto{
    /*height: 100vh;*/
    /*width:808px;*/
    width: 57%;
}
@media(min-width: 1440px){
    .container{
        display: flex;
        overflow: hidden;
        width: 100%;
        /*height: 780px;*/
        /*min-height:780px;*/
    }

    .wrapper{
        width: 43%;
        min-width: 632px;

        /*height: 632px;*/
    }
    .img-auth{
        width: 57%;

    }
}



.login-container-terminal {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login-wrapper-terminal {
    display: flex;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    row-gap: 54px;
    padding: 54px;
}