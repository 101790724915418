.rounded-remove-item-button {
    position: relative;
    width: 24px;
    height: 24px;
    border: 1.5px solid #ED1C24;
    border-radius: 50%;
    transition-duration: .5s;
    &::after,
    &::before {
        rotate: 45deg;
        content: "";
        display: block;
        position: absolute;
        background: #ED1C24;
        border-radius: 1px;
        margin: auto;
    }
    &::after {
        top: 25%;
        left: 46.45%;
        width: 7.1%;
        height: 50%;
    }
    &::before {
        top: 46.45%;
        left: 25%;
        width: 50%;
        height: 7.1%;
    }
    &:hover {
        cursor: pointer;
        border-color: #EEEEEE;
        background: #ED1C24;
        transition-duration: .5s;
        box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.1);
        &::after {
            background: #EEEEEE;
        }
        &::before {
            background: #EEEEEE;
        }
    }
}