@import "src/styles/default/variables";
@import "src/styles/default/fonts";

$calendar-content-width: 260px;
//FIX
.react-datepicker__aria-live {
  display: none;
}
.react-datepicker {
  @include font-regular-small();
  border: none;
  padding: 10px;
  width: $calendar-content-width;
  position: relative;
  background: $white-primary;
  -webkit-box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
  &__header {
    display: flex;
    flex-direction: column;
    padding: 0;
    background: $white-primary;
    border-bottom: none;
  }
  &__day-names {
    display: flex;
    margin: $indent-bold-thin 0;
    justify-content: space-between;
  }
  &__day-name {
    @include font-bold-medium;
    width: calc($calendar-content-width / 7);
    margin: 0;
    display: flex;
    justify-content: center;
  }
  &__current-month {
    @include font-regular-medium();
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    height: 30px;
  }
  &__day--selected {
    background: $blue-light-primary;
  }
  &__month {
    margin: 0;
  }
  &__month-container {
    display: flex;
    flex-direction: column;
  }
  &__day {
    margin: 0;
    padding: 0;

    width: calc($calendar-content-width / 7);

    display: flex;
    justify-content: center;

    transition-duration: .5s;
    &:hover {
      margin: 0;
      padding: 0;

      border-radius: 0;
      background: $blue-light-primary;
      transition-duration: .5s;

      cursor: pointer;
    }
    &--weekend {
      color: $red-primary;
    }
    &--disabled {
      color: $gray-secondary;
      &:hover {
        background: $blue-light-secondary;
      }
    }
    &--in-range {
      background: $stroke-light-2;
    }
    &--in-selecting-range {
      background: $stroke-light-1;
    }
    &--selected {
      background: $blue-light-secondary;
    }
    &--range-start,
    &--range-end {
      background: $blue-dark-secondary;
    }
  }
  &__day--outside-month {
    color: $gray-secondary;
    opacity: 30%;
  }
  &__week {
    display: flex;
    justify-content: space-between;
  }
  &__navigation--previous,
  &__navigation--next {
    position: absolute;
    display: flex;
    align-items: center;
  }
  &__navigation--previous,
  &__navigation--next {
    display: block;
    content: "";
    background: $white-primary;

    top: 20px;
    left: 220px;

    height: 12px;
    width: 12px;

    border-color: $black-primary;
    border-style: solid;
    cursor: pointer;
    transform: rotate(45deg);
    transition-duration: 1s;

    text-indent: -999em;
    &:hover {
      border-color: $gray-primary;
      transition-duration: .5s;
      border-spacing: 10px;
    }
    &:active {
      border-color: $black-primary;
      transition-duration: .3s;
      transform: scale(1.25) rotate(45deg);
    }
  }
  &__navigation--next {
    border-width: 2px 2px 0 0;
    top: 20px;
    left: 220px;
  }
  &__navigation--previous {
    border-width: 0 0 2px 2px;
    top: 20px;
    left: 30px;
  }
}
