.guarantee-compenstation {
    // width: 1160px;
    // margin: 60px auto;
    // min-height: 44dvh;
    // &__lack-of-info {
    //     font-size: 36px;
    //     font-weight: 400;
    //     display: flex;
    //     justify-content: center;
    //     margin-top: 160px;
    //     color: #bcbec3;
    // }
    width: 1160px;
    margin: 60px auto;
    min-height: 44dvh;
    &__lack-of-info {
        font-size: 36px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        margin-top: 160px;
        color: #bcbec3;
    }
    &__table {
        width: 1160px;
        &__header, 
        &__row {
            &__first,
            &__second,
            &__third,
            &__fourth {
                height: 96px;
                display: flex;
                align-items: center;
                padding-right: 16px;
            }
            &__first {
                width: 432px;
            }
            &__second {
                width: 215px;
            }
            &__third {
                width: 263px;
            }
            &__fourth {
                width: 250px;
            }
        }
        &__header {
            display: flex;
            height: 96px;
            font-size: 12px;
            line-height: 16.2px;
            letter-spacing: 1.4px;
            text-transform: uppercase;
            color: #6D6E71;
            border-bottom: .5px solid #EEEEEE;
        }
        &__body {
            &__row {
                display: flex;
                align-items: center;
                height: 96px;
                border-bottom: .5px solid #EEEEEE;
            }
        }
    }
}