.search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
    margin-bottom: 20px;
}

.colored-logo {
    width: 168px;
    /*align-content: flex-start;*/
    /*right: 0;*/
    /*    justify-items: left;*/
}

.input-container {
    /*width: 64vw;*/
    /*display: flex;*/
    /*align-self: flex-start ;*/
}

.input-container img {
    width: 1.5vw;
}

.input-header {
    height: 36px;
    border: none;
    border-bottom: 1px solid rgba(238, 238, 238, 1);
    width: 786px;
    font-size: 14px;
    font-weight: 450;
    color: rgba(18, 18, 18, 1);
    background: url("ic_search.svg") no-repeat center left 5px;
    background-size: 25px;
    padding-left: 45px;
}

.input-header::placeholder {
    text-align: left;
    font-weight: 400;
}

.logout {
    cursor: pointer;
}

.logout span {
    font-size: 16px;
    line-height: 20px;
}

.logout span:hover {
    opacity: 0.5;
}
