@font-face {
  font-family: 'Avenir Next Cyr';
  src: url("../../assets/fonts/AvenirNextCyr-Thin.ttf") format("truetype");
  font-weight: 200;
  font-style: auto;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url("../../assets/fonts/AvenirNextCyr-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: auto;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url("../../assets/fonts/AvenirNextCyr-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: auto;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url("../../assets/fonts/AvenirNextCyr-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: auto;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url("../../assets/fonts/AvenirNextCyr-Bold.ttf") format("truetype");
  font-weight: 800;
  font-style: auto;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url("../../assets/fonts/AvenirNextCyr-Demi.ttf") format("truetype");
  font-weight: 700;
  font-style: auto;
}

@mixin font-regular-big () {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

@mixin font-bold-big () {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

@mixin font-regular-medium () {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

@mixin font-bold-medium () {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}

@mixin font-regular-small () {
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
}