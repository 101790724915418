.container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 40px;
  width: 700px;
}

.text {
  text-align: center;
}

.groupButton {
  display: flex;
  align-items: stretch;
  column-gap: 40px;
}