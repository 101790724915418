
.modal-widgets{
        padding: 60px 95px ;
    display: block !important;
    height: 906px;
    width: 1160px;
    transition: 0.5s;
    tab-index: -1;

&__modal-dialog{
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    gap:4vh;
    font-size: 16px;
    justify-content: space-between;
    overflow-y: initial !important
}
    &__button {
        width: 275px;
        height: 41px;
        border: 2px solid #6DCDF6;
        border-radius: 42px;
        background: #FFFFFF;
        font-weight: 450;
        letter-spacing: 0.15em;
        transition-duration: 1s;
        text-transform: uppercase;
        margin-bottom: 7px;
        &:hover {
            cursor: pointer;
            background: #f7fafd;
            transition-duration: 1s;
            transform: scale(1.01);
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }
        &:disabled {
            background: rgba(245, 245, 245, 1);
            border: none;
        }
    }

}

.scroll-bar{
    overflow-y: scroll;
    overflow-x: hidden;
    height: 566px;
    width: 964px;


}

.section::-webkit-scrollbar {
    width: 6px;
    border: none;

}

.section::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border: none;
}

.section::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #8070d4;
    background-clip: content-box;
    border-radius: 100px;

}


h1{
    font-weight: 400;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
}
.check-box-container{
    justify-content: space-evenly;
    align-items: flex-start;
    display: flex;
    flex-basis: 0;
    width: 964px;

    flex-direction: column;
    /*width: 65vw;*/
    /*height: 50vh;*/
}
.check-box-wrapper{
    align-items: center;
    display: flex;
    width: 964px;
height:50px ;
    border-bottom: 1px solid rgba(238, 238, 238, 1);
}
.check-box-wrapper input{
    width: 16px;
    height: 16px;
}
.check-box-wrapper span{
    align-self: center;
    font-size: 14px;
    padding-left: 2.2vw;
    line-height: 135%;
    font-weight: 400;
    color: #000000;
}
.big-button2 {
    //width: 67vw;
margin-top: 2vh;
    margin-bottom: 1vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.bigButton{
    /*width: 16vw;*/
    /*display: flex;*/
    align-items: center;
    border-radius: 42px;
    background-color: #fff;
    border: 2px solid rgba(109, 205, 246, 1);
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
    /*color: #000000;*/
    letter-spacing: 0.15em;
    line-height: 21px;


}
.bigButtonDiv:hover {
    opacity: 0.5;
    cursor:pointer;

}

.bigButton:disabled{
    /*background: #F5F5F5;*/
    background: rgba(245, 245, 245, 1);
    border:none;
}
