.error500-unep {
  margin: auto;
  max-width: 50vw;
  background: white;
  min-height: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 40px;
  font-size: 21px;
  color: #6D6E71;
  font-weight: 450;
  justify-content: center;
  gap: 30px;
}
