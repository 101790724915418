.container {
  height: 100%;
  padding: 0 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  font-size: 40px;
}

.description {
  color: #EEEEEE;
}