.unpaid-practice {

  width: 1160px;
  margin: 62px auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;

  &__notification {
    width: 600px;
    font-weight: 450;
    font-size: 12px;
    line-height: 135%;
  }

  &-student {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &-comment {
    min-height: 40px;
    border: none;
    border-bottom: #EEEEEE 1px solid;
    padding-right: 75px;

    &__container {
      display: flex;
      flex-direction: column;
      position: relative;

      &__counter {
        position: absolute;
        right: 0;
        bottom: 40px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 450;
      }
    }

    &__label {
      font-size: 12px;
      line-height: 14px;
    }

    &__underline {
      color: #97999B;
      margin-top: 6px;
      font-size: 11px;
      line-height: 15px;
    }

  }

  &-commentError {
    min-height: 40px;
    border: none;
    border-bottom: #ED1C24 1px solid;
  }

  &__student {
    width: 650px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;

  }

  &__button {
    padding: 0 20px;
    width: 657px;
    height: 66px;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    &__blue-plus {
      cursor: pointer;
    }

    &__red-plus {
      cursor: pointer;
    }

    span {
      font-weight: 450;
      font-size: 21px;
      line-height: 42px;
    }
  }
}
