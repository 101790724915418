.register-form__wrapper__label,
.register-form__wrapper__label-invalid,
.register-form__wrapper__input,
.register-form__wrapper__input-invalid,
.register-form__wrapper__checkbox-text,
.register-form__wrapper__login,
.register-form__button,
.colored-text {
  line-height: 135%;
  font-size: 14px;
}
.register-form {
  display: flex;
  flex-direction: column;
  width: 350px;
  margin: 200px auto auto auto;
  /*padding-left:90px;*/
  gap: 30px;
   &__logo {
     width: 136px;
     margin-bottom: 30px;
   }
  &__wrapper {
    display: flex;
    width: 275px;
    flex-direction: column;
    position: relative;
    &__label {
      font-size: 14px;
      max-width: 275px;
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 23px;
    }
    &__label-invalid {
      color: rgba(237, 28, 36, 1);
    }
    &__input {
      height: 40px;
      background: #fff;
      border: none;
      border-bottom: 1px solid rgba(238, 238, 238, 1);
      font-weight: 450;
      padding-right: 20px;
    }
    &__input-invalid {
      height: 40px;
      font-weight: 450;
      padding-right: 20px;
      background: #fff;
      border: none;
      border-bottom: 1px solid rgba(237, 28, 36, 1) ;
    }
    &__icon {
      position: absolute;
      cursor: pointer;
      bottom: 10px;
      right: 0;
    }
    &__checkbox-text {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      margin-bottom: 10px;
      gap: 8px;
    }
  }
  &__button {
    width: 275px;
    height: 41px;
    border: 2px solid #6DCDF6;
    border-radius: 42px;
    background: #FFFFFF;
    font-weight: 450;
    letter-spacing: 0.15em;
    transition-duration: 1s;
    text-transform: uppercase;
    margin-bottom: 7px;
    &:hover {
      cursor: pointer;
      background: #f7fafd;
      transition-duration: 1s;
      transform: scale(1.01);
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    &:disabled {
      background: rgba(245, 245, 245, 1);
      border: none;
    }
  }
  &__support {
    margin-top: 60px;
  }
}
.colored-texts{
  color: #6DCDF6;
  font-size: 14px;
  cursor: pointer;
}
