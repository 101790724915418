.date-selector-list {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  width: 860px;


  &__add-date-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 325px;
    height: 69px;
    padding: 0 20px;
    box-shadow: 0px 6px 24px 0px #0000000F;

  }

  &__wrapper {
    position: relative;
    box-shadow: 0px 6px 24px 0px #0000000F;
    outline: none;
    border: none;

    &--focus {
      outline: 1px solid #6DCDF6;
    }

    .rounded-remove-item-button {
      position: absolute;
      top: 33%;
      left: 75%;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    height: 69px;
    padding: 0 20px;
    background: #FFFFFF;
    cursor: pointer;

    &__buffer {
      width: 32px;
    }
  }
}

.errorBorder {
  border: 1px solid #ED1C24;
}

.focus {
  outline: 1px solid #6DCDF6;

}
