.documents-rows__container{
  display: flex;
  flex-direction: row;
}
.docs-item{
  //position: relative;
  cursor: pointer;
  width: 373px;
  border: black;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.06);
  flex-direction: column;
  padding: 30px;
  margin-top: 50px;
  margin-right:20px;
  transition: transform 250ms, opacity 400ms;

}
.docs-item:hover{
  -webkit-box-shadow: 0px 5px 15px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 15px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 15px 2px rgba(34, 60, 80, 0.2);
  transform: scale(1.05);
  opacity: 1;
}
.header-item{
  //position: absolute;
  font-size: 21px;
  line-height: 135%;
  color: #121212;
  margin-bottom: 10px;
}
.description-item{
  //position: absolute;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  //margin-top: 10px;
}
