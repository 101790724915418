.login-container {
  display: flex;
  margin: auto;
  width: 1920px;
  max-width: 100%;
}
.login-wrapper {
  width: 960px;
  height: 100dvh;
  /*height: 1080px;*/
  //min-width: 680px;
  //min-height: 1480px;
}
.login__support {
  width: 350px;
  margin: 60px auto auto auto;
}
.login-img-auth {
    width: calc(100vw - 960px);
    background-image: url("login_pics_adaptive/960x1080.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

//
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .login-container {
    max-width: 600px;
    width: 100%;
    margin: auto;
  }
  .login-wrapper {
   padding: 20px;
  }
  .login__support {
    //margin: 60px 0 0 100px !important;
  }
  .login-img-auth {
    display: none;
  }

}
@media only screen and (min-width: 601px) and (max-width: 899px){
  .login-container {
    width: 900px;
/*    height: 900px;*/
  }
  .login {
    //margin: auto !important;
  }
  .login-wrapper {
    width: 450px;
/*    height: 900px;*/
    display: flex;
    flex-direction: column;
    //.login {
    //  margin: auto;
    //  padding: 20px;
    //}

  }

  .login_support {
    margin: 0;
  }
  .login-img-auth {
    width: calc(100vw - 450px);
/*    height: 900px;*/
    background-image: url("./login_pics_adaptive/720x900.jpg");
    background-repeat: no-repeat;
  }
}


@media only screen and (min-width: 900px) and (max-width: 1279px){
  .login-container {
    width: 1280px;
   /* height: 900px;*/
  }
  .login {
    //margin: auto !important;
  }
  .login-wrapper {
    width: 790px;
/*    height: 900px;*/
    display: flex;
    flex-direction: column;
    //.login {
    //  margin: auto;
    //  padding: 20px;
    //}

  }

  .login_support {
    margin: 0;
  }
  .login-img-auth {
    width: calc(100vw - 790px);
    background-image: url("./login_pics_adaptive/960x1080.jpg");
    /*height: 900px;*/
    /*background-image: url("./login_pics_adaptive/800x900.png");*/
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 1921px) {
  .login-container {
    width: 2560px;
/*    height: 1440px;*/
  }
  .login-wrapper {
    width: 1280px;
  }
  .login-img-auth {
    width: 1280px;
    background-image: url("./login_pics_adaptive/1280x1440.jpg");
    background-repeat: no-repeat;
  }
}
//
/* Small devices (portrait tablets and large phones, 600px and up) */
//@media only screen and (min-width: 600px) {
//  .login-container {
//    margin: auto;
//    display: flex;
//  }
//  .login-wrapper {
//    width: 380px;
//    padding: 20px 20px 0 20px;
//    height: 768px;
//    .login {
//      margin: 100px auto auto auto;
//    }
//  }
//  .login__support {
//    margin: 60px 0 0 0;
//  }
//  .login-img-auth {
//    //display: none;
//    //width: 228px;
//    background-image: url("./login_pics_adaptive/683x768.png");
//    background-repeat: no-repeat;
//  }
//}

/* Medium devices (landscape tablets, 768px and up) */
//@media only screen and (min-width: 768px) {
//  .login-container {
//    width: 992px;
//    display: flex;
//  }
//  .login-wrapper {
//    width: 540px;
//    padding: 20px 20px 0 20px;
//    height: 1080px;
//    .login {
//      margin: 100px auto auto auto;
//    }
//  }
//  .login-img-auth {
//    //width: 471px;
//    background-image: url("./login_pics_adaptive/960x1080.png");
//    background-repeat: no-repeat;
//  }
//}

///* Large devices (laptops/desktops, 992px and up) */
//@media only screen and (min-width: 992px) {
//  .login-container {
//    width: 960px;
//    height: 1280px;
//  }
//  .login-wrapper {
//    width: 540px;
//    padding: 20px 20px 0 20px;
//    //height: 900px;
//    .login {
//      margin: 100px auto auto auto;
//    }
//  }
//  .login-img-auth {
//    //width: 600px;
//    background-image: url("./login_pics_adaptive/960x1080.png");
//    background-repeat: no-repeat;
//  }
//}
