
.question-hint {
  transition-duration: 1s;

  &:hover {
    filter: grayscale(1);
    transform: scale(1.05);
    transition-duration: 1s;
    cursor: pointer;
  }
}