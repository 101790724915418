.useful-links {
  width: 1160px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 30px;


  &__items {
    display: flex;
    flex-wrap: wrap;
  }
}

