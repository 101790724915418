.warning {
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: center;
  font-weight: 400;
  font-size: 21px;
  color: #818C99;
  align-items: center;
  padding: 30px 40px;
}
