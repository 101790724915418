.form {
  display: flex;
  flex-direction: column;
  row-gap: 54px;
}

.logo {
  width: 50%;
}

.textBold {
  font-weight: 450;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  row-gap: 10px;
}

.errorText {
  color: #ED1C24;
  font-size: 18px;
}


.timer {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  color: #97999B;
}

.counter {
  color: #007AC2;
}

.actionButton {
  background-color: #007AC2;
  color: #FFFFFF;
}

.actionButton:disabled {
  background-color: #D7D7D7;
  color: #97999B;
}