.important-tasks {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__error {
    height: 20px;
    font-size: 12px;
    color: #e00000;
    line-height: 20px;
  }

  &__legend {
    width: 587px;
    color: #121212;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 21.6px;
  }

  &__struct-dropdown,
  &__struct-dropdown-error {
    width: 587px;
    height: 40px;
    border-bottom: 1px solid #eeeeee;
    position: relative;

    &__selected {
      height: 40px;
      display: flex;
      align-items: center;
    }

    &__dropdown-active {
      width: 587px;
      z-index: 1000;
      background: #FFFFFF;
      position: absolute;
      -webkit-box-shadow: 0px 5px 10px 2px rgba(171, 176, 180, 0.2);
      -moz-box-shadow: 0px 5px 10px 2px rgba(171, 176, 180, 0.2);
      box-shadow: 0px 5px 10px 2px rgba(171, 176, 180, 0.2);
    }

    &__dropdown-hidden {
      display: none;
    }

    &__arrow {
      &-up,
      &-down {
        transition-duration: .5s;
        left: 560px;
        position: absolute;
        display: inline-block;
        width: 12px;
        height: 12px;
        border-top: 1.5px solid #747475;
        border-right: 1.5px solid #747475;
        transform: rotate(-45deg);
      }

      &-down {
        transform: rotate(135deg);
      }
    }
  }

  &__struct-dropdown-error {
    border-bottom: 1px solid #e00000;
  }

  &__workers {
    margin-top: 15px;
    width: 587px;
    display: flex;
    gap: 30px;

    &__selected {
      & div {
        display: flex;
        align-items: center;
      }

      width: 419px;
      min-height: 40px;
      font-size: 12px;
      line-height: 19px;
    }

    &__arrow {
      &-up,
      &-down {
        transition-duration: .5s;
        top: 20px;
        left: 400px;
        position: absolute;
        display: inline-block;
        width: 12px;
        height: 12px;
        border-top: 1.5px solid #747475;
        border-right: 1.5px solid #747475;
        transform: rotate(-45deg);
      }

      &-down {
        transform: rotate(135deg);
      }
    }

    &__dd-container {
      position: relative;
      width: 419px;
      //height: 40px;
      border-bottom: 1px solid #eeeeee;
    }

    &__dd-container-error {
      position: relative;
      width: 419px;
      //height: 40px;
      border-bottom: 1px solid #e00000;
    }

    &__input {
      height: 40px;
      width: 138px;
      outline: none;
      border: none;
      border-bottom: 1px solid #eeeeee;
    }

    &__error {
      height: 40px;
      width: 138px;
      outline: none;
      border: none;
      border-bottom: 1px solid #e00000;;
    }

    &__dropdown-hidden {
      display: none;
    }

    &__dropdown-active {
      width: 419px;
      max-height: 367px;
      overflow: auto;
      z-index: 1000;
      position: absolute;
      background: #FFFFFF;
      -webkit-box-shadow: 0px 5px 10px 2px rgba(171, 176, 180, 0.2);
      -moz-box-shadow: 0px 5px 10px 2px rgba(171, 176, 180, 0.2);
      box-shadow: 0px 5px 10px 2px rgba(171, 176, 180, 0.2);
    }

    &__list-item {
      color: #767676;
      min-height: 60px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 13px 12px;

      &:hover {
        color: #6DCDF6;
        cursor: pointer;
        background: #FAFAFA;
      }

      &__fio {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }

      &__position {
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 11px;
      }

      &__struct {
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 10px;
      }

    }

    &__delete-worker {
      cursor: pointer;
    }
  }
}

.dropdown-important-task-container {
  width: 587px;
}

.dropdown-important-task {
  position: relative;
  border-bottom: 1px solid #eeeeee;

  &-container {
    border: none;
  }

  &__item__text {
    font-size: 14px;
  }

  &__item__underline {
    font-size: 12px;
    line-height: 20px;
    color: #e00000;
    border: none;
  }

  &__label {
    font-size: 12px;
    line-height: 19px;
  }

  &__select-hidden,
  &__select-active {
    height: 40px;
    display: flex;
  }

  &__select__selected {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  &__options-hidden {
    display: none;
  }

  &__options-active {
    z-index: 1000;
    max-height: 324px;
    overflow: auto;
    width: 587px;
    position: absolute;
    list-style-type: none;
    top: 43px;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 5px 10px 2px rgba(171, 176, 180, 0.2);
    -moz-box-shadow: 0px 5px 10px 2px rgba(171, 176, 180, 0.2);
    box-shadow: 0px 5px 10px 2px rgba(171, 176, 180, 0.2);

    & li {
      color: #6D6E71;
      cursor: pointer;
      padding: 13px 12px;
      height: 40px;
      display: flex;
      align-items: center;

      &:hover {
        background: #FAFAFA;
        color: #6DCDF6;
      }
    }
  }

  &__select {
    &__arrow-up,
    &__arrow-down {
      transition-duration: .5s;
      position: absolute;
      top: 10px;
      left: 570px;
    }

    &__arrow-up {
    }

    &__arrow-down {
      transform: rotate(180deg);
    }
  }

}

.dropdown-important-task__select-danger {
  border-bottom: 1px solid #e00000 !important;
}

.struct-sub-menu__sub-menu-shrunk {
  display: none;
}

.sub-menu-shrunk {
  display: none;
}

.tree {
  max-height: 376px;
  overflow: auto;

  &__buttons {
    display: flex;
    height: 60px;

    &__accept,
    &__decline {
      color: #6D6E71;
      font-size: 19px;
      line-height: 26px;
      font-weight: 400;
      transition-duration: 1s;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(586px / 2);

      &:hover {
        background: #FAFAFA;
        color: #6DCDF6;
      }
    }
  }
}

.menu-tree-struct {
  color: #333333;
  padding: 12px 13px;
  cursor: pointer;
  min-height: 28px;
  transition-duration: .5s;
  font-size: 14px;

  &:hover {
    background: #efefef;
  }

  &__sub-menu {
    padding-left: 20px;
    min-height: 28px;
  }

  &__arrow,
  &__arrow__open {
    position: relative;
    display: inline-block;
    top: -2px;
    width: 6px;
    height: 6px;
    margin-right: 10px;
    border-top: 1px solid #767676;
    border-right: 1px solid #767676;
    transform: rotate(45deg);
    transition-duration: .5s;
  }

  &__arrow__open {
    border-top: 1px solid #6DCDF6;
    border-right: 1px solid #6DCDF6;
    transform: rotate(135deg);
  }

  &__buffer {
    margin-left: 16px;
  }

  &__selected-arrow {
    position: relative;
    display: inline-block;
    width: 8px;
    height: 14px;
    border-bottom: 2px solid #6DCDF6;
    border-right: 2px solid #6DCDF6;
    float: right;
    transform: rotate(35deg);
  }
}

.selected-menu-tree-struct {
  position: relative;
  background: #FAFAFA;
  color: #6DCDF6;
}
