.ui-u-button {
  width: 275px;
  height: 41px;
  font-weight: 450;
  font-size: 14px;
  line-height: 21px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid #6DCDF6;
/*  border: 2px solid red;*/

  border-radius: 42px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15em;
  text-transform: uppercase;
  transition-duration: 1s;

  &[disabled] {
    border: 2px solid #F5F5F5;
  /*  border: 2px solid red;*/

    width: 275px;
    height: 41px;
    font-weight: 450;
    font-size: 14px;
    line-height: 21px;
    background: #F5F5F5;
    color: #818C99;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 42px;
    /* identical to box height, or 150% */
    letter-spacing: 0.15em;
    text-transform: uppercase;
    transition-duration: 1s;

    &:hover {
      cursor: pointer;
      transition-duration: 1s;
      background: #818C99;
      color: whitesmoke;
      transform: scale(1.01);
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
  }

  &:hover {
    cursor: pointer;
    transition-duration: 1s;
    background: #6DCDF6;
    color: whitesmoke;
    transform: scale(1.01);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }



}
