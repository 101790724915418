.container {
    display: flex;
   
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    row-gap: 634px;
    padding: 95px 54px;
}