.container {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 8px 0;
}

.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 20px;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.06);
}

.button:disabled {
  cursor: default;
  opacity: 0.3;
}

.arrowForth {
  transform: rotate(180deg);
}

.title {
  font-size: 24px;
  font-weight: 400;
  text-transform: capitalize;
}